import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import { protectPage } from '../../../modules/users'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import { useNavigate, useParams } from '../../../modules/navigation'
import { useJobRequest } from '../../../modules/jobRequests'
import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import MobileHeader from '../../../components/layout/MobileHeader/MobileHeader'
import { Error404Page } from '../../errors'
import JobRequestPageContent from './JobRequestPageContent'

const JobRequestPage: React.FC = () => {
  const { t } = useTranslation()
  const { replace } = useNavigate()
  const { jobRequestId } = useParams()
  const { data: jobRequest, loading, error } = useJobRequest(jobRequestId)

  const {
    data: currentCompanyBranch,
    error: currentCompanyBranchError,
    loading: currentCompanyBranchLoading,
  } = useCurrentCompanyBranch()

  const companyReady = (currentCompanyBranch?.setupProgress ?? 0) === 100

  useEffect(() => {
    if (!!currentCompanyBranchError || currentCompanyBranchLoading) {
      return
    }
    if (!companyReady) {
      replace(Route.BranchSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyReady, currentCompanyBranchError, currentCompanyBranchLoading])

  if (currentCompanyBranchLoading || loading) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }

  if (!!currentCompanyBranchError || !currentCompanyBranch?.approved) {
    return <Error404Page />
  }

  if (error) {
    return <ContainedPage><ServerError error={error} /></ContainedPage>
  }

  if (!companyReady || !currentCompanyBranch || !jobRequest) {
    return null
  }

  return (
    <ContainedPage
      mobileHeader={(
        <MobileHeader
          backlinkTo={Route.JobRequests}
          title={t('pages.jobRequests.title')}
        />
      )}
    >
      <JobRequestPageContent jobRequest={jobRequest} />
    </ContainedPage>
  )
}

export default protectPage(JobRequestPage)
