/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { set } from 'date-fns/set'
import { isFuture } from 'date-fns/isFuture'
import { subYears } from 'date-fns/subYears'

import { BarChart, type BarChartData } from '../../../../components/charts'
import { useDateStringFormatter } from '../../../../utils/date'
import { formatPrice } from '../../../../utils/price'
import { Currency } from '../../../../common/amount'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../../../../utils/string'

type AcceptedQuotesValueChartProps = {
  data: BarChartData[]
  monthlyView: boolean
}

const AcceptedQuotesValueChart: React.FC<AcceptedQuotesValueChartProps> = ({
  data,
  monthlyView,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateStringFormatter()
  const months: string[] = Object.values(t('date.months', { returnObjects: true }))
  const shortMonths: string[] = Object.values(t('date.monthsShort', { returnObjects: true }))

  /**
   * format value (price)
   */
  const formatValue = (value: number) => {
    return formatPrice({ price: value, currency: Currency.CAD }, { showDecimals: false, compact: true })
  }

  /**
   * format value label (displayed inside tooltip)
   */
  const formatLabel = (label: string) => {
    if (monthlyView) {
      const [day, month] = label.split('-')
      let refDate = set(new Date(), { date: parseInt(day), month: parseInt(month) - 1 })
      if (isFuture(refDate)) {
        refDate = subYears(refDate, 1)
      }
      return formatDate(refDate)!
    }
    const [year, month] = label.split('-')
    const monthName = months[parseInt(month) - 1]
    return `${capitalizeFirstLetter(monthName)!} ${year}`
  }

  /**
   * format the axis label
   */
  const formatAxisLabel = (value: string) => {
    if (monthlyView) {
      return value.split('-')[0]
    }
    const monthNumber = parseInt(value.split('-')[1])
    return capitalizeFirstLetter(shortMonths[monthNumber - 1])!
  }

  /**
   * format the count value (nb of sales)
   */
  const formatCountValue = (count?: number) => {
    return t('nbQuotes', { count })
  }

  return (
    <BarChart
      data={data}
      bars={[{
        key: 'value',
        label: t('pages.admin.dashboard.acceptedQuotes.acceptedQuotesValue'),
        color: 'rgb(148, 163, 184)',
      }]}
      valueFormatter={formatValue}
      labelFormatter={formatLabel}
      xAxisFormatter={formatAxisLabel}
      countFormatter={formatCountValue}
    />
  )
}

export default AcceptedQuotesValueChart
