import { mergeClassName } from '../../utils/mergeClassName'
import PaperTitle from './PaperTitle'

type PaperProps = {
  children?: React.ReactNode
  title?: React.ReactNode
  titleIcon?: React.ReactNode
  compact?: boolean
  className?: string

}

const Paper: React.FC<PaperProps> = ({
  children,
  title,
  titleIcon,
  compact = false,
  className,
}) => {
  const hasTitle = !!title
  return (
    <div className={mergeClassName(
      'mb-6 3xl:mb-8 rounded-md border border-zinc-200 bg-white shadow-md shadow-neutral-300/30',
      compact && 'mb-4 3xl:mb-6 shadow-sm ',
      className,
    )}
    >
      { hasTitle && (
        <PaperTitle icon={titleIcon}>{ title }</PaperTitle>
      ) }
      <div className={mergeClassName(
        'p-4 lg:p-8',
        compact && 'p-2 lg:p-6',
      )}
      >
        { children }
      </div>
    </div>
  )
}

export default Paper
