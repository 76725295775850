import { useTranslation } from 'react-i18next'
import DashboardIcon from '@mui/icons-material/SpaceDashboardOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import AvailabilityIcon from '@mui/icons-material/CalendarMonthOutlined'
import JobRequestIcon from '@mui/icons-material/MoveToInboxOutlined'

import MenuItem from './MenuItem'
import Route from '../../../app/Route.enum'
import { getCompanyBranchRouteRegExp, useCurrentCompanyBranch, useOnBehalfState } from '../../../modules/companies'
import { Permission, usePermission } from '../../../modules/users'
import { usePendingRequestsNotifications } from '../../../modules/jobRequests'

const PartnerMenuContent: React.FC = () => {
  const { t } = useTranslation()
  const { onBehalf } = useOnBehalfState()
  const { data: currentcompanyBranch } = useCurrentCompanyBranch()
  const canManageOwnJobRequests = usePermission(Permission.canManageOwnJobRequests)
  const pendingRequestsNotifications = usePendingRequestsNotifications()
  const nbPendingRequests = pendingRequestsNotifications?.count ?? 0

  return (
    <>
      <MenuItem
        to={onBehalf ? Route.CompaniesOnBehalf : Route.Dashboard}
        label={t('menu.partner.dashboard')}
        Icon={DashboardIcon}
        exact
      />

      <MenuItem
        to={Route.BranchSettingsLandingPage}
        label={t('menu.partner.branches')}
        Icon={TruckIcon}
        matchRegex={getCompanyBranchRouteRegExp(Route.BranchSettings)}
      />

      <MenuItem
        to={Route.BranchAvailabilityLandingPage}
        label={t('menu.partner.availability')}
        Icon={AvailabilityIcon}
        matchRegex={getCompanyBranchRouteRegExp(Route.BranchAvailability)}
      />

      { canManageOwnJobRequests && currentcompanyBranch?.approved && (
        <MenuItem
          to={Route.JobRequestsLandingPage}
          label={t('menu.partner.jobRequests')}
          Icon={JobRequestIcon}
          matchRegex={getCompanyBranchRouteRegExp(Route.JobRequests)}
          notifications={nbPendingRequests}
        />
      ) }
    </>
  )
}

export default PartnerMenuContent
