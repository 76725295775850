import { Truck } from '../../../companies'

export const trucksVolume: Partial<Record<Truck, number>> = {
  [Truck.Trucks16ft]: 800,
  [Truck.Trucks20ft]: 1100,
  [Truck.Trucks22ft]: 1300,
  [Truck.Trucks24ft]: 1500,
  [Truck.Trucks26ft]: 1700,
  [Truck.Trucks28ft]: 2100,
}
