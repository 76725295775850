import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Chip from '@mui/material/Chip'
import PromoCodeIcon from '@mui/icons-material/LocalOffer'

import {
  type PaginatedQuery,
  type PaginatedQueryResult,
  type PaginatedQueryResultData,
  type QueryResult,
} from '../../graphQl'
import * as queries from './promoCodes.queries'
import * as mutations from './promoCodes.mutations'
import { type FullPromoCode, type PromoCode } from './promoCodes.models'
import { type PromoCodePayload } from './promoCodes.types'
import { applyPromoCode } from './promoCodes.utils'
import { useTranslation } from 'react-i18next'
import { PromoCodeType } from './promoCodes.enums'
import Price from '../../../components/Price'
import { type Amount, Currency } from '../../../common/amount'
import { type Quote } from '../../quotes'
import { mergeClassName } from '../../../utils/mergeClassName'

type UsePromoCodesHookResult = {
  fetchPromoCodes: (variables: PaginatedQuery) => Promise<PaginatedQueryResultData<FullPromoCode>>
} & PaginatedQueryResult<FullPromoCode>

/**
 * returns all promo codes
 * query optimized for data table
 */
export const usePromoCodes = ({ reload = false, ...queryArgs } = {}): UsePromoCodesHookResult => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, previousData, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getPromoCodes,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const fetchPromoCodes = async (query?: PaginatedQuery) => {
    const { data } = await fetch({ variables: { query } })
    return data?.admin_getPromoCodes
  }

  return {
    fetchPromoCodes,
    data: (data ?? previousData)?.admin_getPromoCodes,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * returns given prospect detail
 */
export const usePromoCode = (promoCodeId: string, { skip = false, reload = false, ...queryArgs } = {}): QueryResult<FullPromoCode | undefined> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getPromoCode,
    {
      skip,
      variables: { promoCodeId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.admin_getPromoCode,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * create a new promo code
 */
export const useCreatePromoCodeAction = () => {
  const [createPromoCode, { data, loading, ...mutationProps }] = useMutation(mutations.createPromoCode)

  return {
    createPromoCode: async (payload: PromoCodePayload) => {
      const { data } = await createPromoCode({
        variables: {
          payload,
        },
      })

      return data.admin_createPromoCode
    },
    data: data?.admin_createPromoCode,
    loading,
    ...mutationProps,
  }
}

/**
 * update a promo code
 */
export const useUpdatePromoCodeAction = () => {
  const [updatePromoCode, { data, loading, ...mutationProps }] = useMutation(mutations.updatePromoCode)

  return {
    updatePromoCode: async (id: string, payload: Partial<PromoCodePayload>) => {
      const { data } = await updatePromoCode({
        variables: {
          id,
          payload,
        },
      })

      return data.admin_updatePromoCode
    },
    data: data?.admin_updatePromoCode,
    loading,
    ...mutationProps,
  }
}

/**
 * delete multiple promo codes
 */
export const useDeletePromoCodesAction = () => {
  const [deletePromoCodes, { data, loading, ...mutationProps }] = useMutation(mutations.deletePromoCodes)

  return {
    deletePromoCodes: async (ids: string[]) => {
      const { data } = await deletePromoCodes({
        variables: {
          ids,
        },
      })

      return data.admin_deletePromoCodes
    },
    data: data?.admin_deletePromoCodes,
    loading,
    ...mutationProps,
  }
}

/**
 * restore multiple promo codes
 */
export const useRestorePromoCodesAction = () => {
  const [restorePromoCodes, { data, loading, ...mutationProps }] = useMutation(mutations.restorePromoCodes)

  return {
    restorePromoCodes: async (ids: string[]) => {
      const { data } = await restorePromoCodes({
        variables: {
          ids,
        },
      })

      return data.admin_restorePromoCodes
    },
    data: data?.admin_restorePromoCodes,
    loading,
    ...mutationProps,
  }
}

/**
 * apply a promo code to a quotes and returns the rebate
 * returns undefined if the rebate is zero
 */
export const usePromoCodeRebate = (quote?: Quote, promoCode?: PromoCode): Amount | undefined => {
  const updatedSubtotal = useQuoteSubtotalWithPromoCode(quote, promoCode)
  if (!quote || !updatedSubtotal) {
    return
  }
  const rebate = quote.subtotal.price - updatedSubtotal.price

  if (rebate === 0) {
    return
  }

  return {
    ...quote.subtotal,
    price: rebate * -1,
  }
}

/**
 * lazy function to calculate promo code on a given price
 */
export const useGetSubtotalWithPromoCode = () => {
  return (amount: Amount, promoCode?: PromoCode) => {
    if (!promoCode) {
      return amount
    }
    return applyPromoCode(amount, promoCode)
  }
}

/**
 * apply a promo code to a quote and returns the new subtotal
 */
export const useQuoteSubtotalWithPromoCode = (quote?: Quote, promoCode?: PromoCode): Amount | undefined => {
  if (!quote) {
    return
  }
  if (!promoCode) {
    return quote.subtotal
  }
  return applyPromoCode(quote.subtotal, promoCode)
}

/**
 * returns a tag with the label of the promo code
 */
export const usePromoCodeLabel = () => {
  const { t } = useTranslation()

  // eslint-disable-next-line react/display-name
  return (promoCode?: PromoCode, standalone = false) => {
    const render = () => {
      if (!promoCode) {
        return null
      }
      return (
        <div className={mergeClassName(
          'flex items-center gap-2 font-body2 text-base font-bold',
          !standalone && 'mx-1',
        )}
        >
          { promoCode.type === PromoCodeType.Amount && (
            <Price
              amount={{ price: promoCode.value, currency: Currency.CAD }}
              showDecimals={false}
              compact
            />
          ) }
          { promoCode.type === PromoCodeType.Percentage && (
            <>
              { promoCode.value }%
            </>
          ) }
          { ' ' }
          <span className={mergeClassName(
            'text-xs',
            !standalone && 'text-gray-300',
          )}
          >
            { t('promoCode.off') }
          </span>
        </div>
      )
    }

    if (!promoCode) {
      return null
    }
    if (standalone) {
      return render()
    }
    return (
      <Chip
        color="success"
        icon={<PromoCodeIcon className="!ml-3 !text-[14px]" />}
        label={render()}
      />
    )
  }
}
