import { useEffect } from 'react'

import { useConfig } from '../../config'
import { useNotifications } from '../../companies'
import { useJobRequests } from './jobRequests.hooks'
import { JobRequestStatus } from './jobRequests.enums'

const JobRequestsFetcher: React.FC = () => {
  const fetchFrequency = useConfig<number>('jobRequests.fetchFrequency')
  const pendingPreviewLimit = useConfig<number>('jobRequests.pendingPreviewLimit')

  const { listening, set } = useNotifications()
  const { fetchJobRequests } = useJobRequests()

  useEffect(() => {
    const run = () => {
      if (!listening) {
        return
      }

      const key = `${listening}:pendingRequestsNotifications`

      fetchJobRequests({
        query: {
          nbResultsPerPage: pendingPreviewLimit,
          page: 1,
        },
        status: JobRequestStatus.Pending,
      }, {
        reload: true,
        companyBranchId: listening,
      }).then(data => {
        set(key, data)
      }).catch(console.error)
    }

    let interval: NodeJS.Timeout | undefined
    clearInterval(interval)

    if (listening) {
      run()
      interval = setInterval(run, fetchFrequency)
    }
    return () => { clearInterval(interval) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening])

  return null
}

export default JobRequestsFetcher
