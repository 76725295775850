import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { getMonth } from 'date-fns/getMonth'
import EditCalendarIcon from '@mui/icons-material/EditCalendarOutlined'

import Paper from '../../../../components/Paper/Paper'
import { type AvailabilityRule, type PricingRule } from '../../../../modules/companies/core/availabilityAndPricing/availabilityAndPricing.models'
import { useTemporalityRuleHandler } from '../../../../modules/companies/core/availabilityAndPricing/temporalityRules/temporalityRules.hooks'
import Rule from './Rule'
import AvailabilityRuleItem from './AvailabilityRuleItem'
import PricingRuleItem from './PricingRuleItem'
import { useCurrentCompanyBranch, usePricingRule, useUpdateCompanyBranchAction } from '../../../../modules/companies'
import { errorNotification, successNotification } from '../../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../../components/errors/useErrorFormatter'
import { mergeClassName } from '../../../../utils/mergeClassName'

type RuleListProps = {
  period: Interval | Date
  availabilityRules: AvailabilityRule[]
  pricingRules: PricingRule[]
  onEditRule: (id: string) => void
  compact?: boolean
  title?: React.ReactNode
}

const RuleList: React.FC<RuleListProps> = ({
  period,
  availabilityRules,
  pricingRules,
  onEditRule,
  compact = false,
  title,
}) => {
  const { t } = useTranslation()
  const getRuleHandler = useTemporalityRuleHandler()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { updateCompanyBranch } = useUpdateCompanyBranchAction()
  const formatError = useErrorFormatter()
  const getPricingRule = usePricingRule()

  const noRule = availabilityRules.length === 0 && pricingRules.length === 0
  const isRange = !!(period as Interval)?.start
  const currentPricingRuleId = !isRange && currentCompanyBranch && getPricingRule(currentCompanyBranch, period as Date)?.id

  const activeAvailabilityRules = useMemo(() => {
    const specific: AvailabilityRule[] = []
    const recurrent: AvailabilityRule[] = []

    const rules = isRange
      ? availabilityRules.filter(rule => !!getRuleHandler(rule.temporality)?.filter(period as Interval))
      : availabilityRules.filter(rule => !!getRuleHandler(rule.temporality)?.isRuleActiveForDate(period as Date))

    rules.forEach(rule => {
      const isRecurrent = getRuleHandler(rule.temporality)?.isRecurrent()
      if (isRecurrent) {
        recurrent.push(rule)
      } else {
        specific.push(rule)
      }
    })
    return {
      recurrent: recurrent.sort((a, b) => parseInt(b.id) - parseInt(a.id)),
      specific: specific.sort((a, b) => parseInt(b.id) - parseInt(a.id)),
    }
  }, [getRuleHandler, availabilityRules, period, isRange])

  const activePricingRules = useMemo(() => {
    const specific: PricingRule[] = []
    const recurrent: PricingRule[] = []

    const rules = isRange
      ? pricingRules.filter(rule => !!getRuleHandler(rule.temporality)?.filter(period as Interval))
      : pricingRules.filter(rule => !!getRuleHandler(rule.temporality)?.isRuleActiveForDate(period as Date))

    rules.forEach(rule => {
      const isRecurrent = getRuleHandler(rule.temporality)?.isRecurrent()
      if (isRecurrent) {
        recurrent.push(rule)
      } else {
        specific.push(rule)
      }
    })
    return {
      recurrent: recurrent.sort((a, b) => b.priority - a.priority),
      specific: specific.sort((a, b) => b.priority - a.priority),
    }
  }, [getRuleHandler, pricingRules, period, isRange])

  const monthName = useMemo(() => {
    if (!isRange) {
      return
    }
    const months = Object.values(t('date.months', { returnObjects: true }))
    return months[getMonth((period as Interval).start)]
  }, [period, t, isRange])

  const deleteAvailabilityRule = async (id: string) => {
    try {
      await updateCompanyBranch({
        availabilityRules: (currentCompanyBranch?.availabilityRules ?? []).filter(r => r.id !== id),
      })
      successNotification(t('pages.companyBranch.availability.ruleList.deletedSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const deletePricingRule = async (id: string) => {
    try {
      await updateCompanyBranch({
        pricingRules: (currentCompanyBranch?.pricingRules ?? []).filter(r => r.id !== id),
      })
      successNotification(t('pages.companyBranch.availability.ruleList.deletedSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (noRule && !compact) {
    return (
      <Paper className="mb-0 flex w-full shrink-0 bg-sky-50/50 py-16 font-body2 child:flex child:grow child:items-center lg:w-[400px] lg:py-0 2xl:w-[500px]">

        <div className="hidden w-full px-4 text-center lg:block">
          <EditCalendarIcon /><br />
          { t('pages.companyBranch.availability.ruleList.noRulesTipLargeScreen') }
        </div>

        <div className="w-full px-4 text-center lg:hidden">
          <EditCalendarIcon /><br />
          { t('pages.companyBranch.availability.ruleList.noRulesTip') }
        </div>
      </Paper>
    )
  }

  const hasSpecificPricingRules = activePricingRules.specific.length > 0
  const hasRecurrentPricingRules = activePricingRules.recurrent.length > 0
  const hasPricingRules = hasSpecificPricingRules || hasRecurrentPricingRules

  const hasSpecificAvailabilityRules = activeAvailabilityRules.specific.length > 0
  const hasRecurrentAvailabilityRules = activeAvailabilityRules.recurrent.length > 0
  const hasAvailabilityRules = hasSpecificAvailabilityRules || hasRecurrentAvailabilityRules

  if (compact && !hasPricingRules && !hasAvailabilityRules) {
    return null
  }

  return (
    <>
      { title }
      <Paper
        className={mergeClassName(
          'mb-0 shrink-0 pb-3 child:p-0 lg:w-[400px] lg:pb-0 child:lg:p-0 2xl:w-[500px]',
          compact && 'lg:w-auto 2xl:w-auto child:lg:pb-3',
        )}
      >

        { monthName
          ? (
            <div className="mb-4 px-4 py-2 text-center font-body2 shadow-md">
              { t('pages.companyBranch.availability.ruleList.pricingPeriodTitle', {
                period: monthName,
              }) }
            </div>
            )
          : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              { (hasPricingRules || !compact) && (
                <div className="px-4 pb-1 pt-4 text-base font-bold">
                  { t('pages.companyBranch.availability.ruleList.pricingTitle') }
                </div>
              ) }
            </>
            ) }

        { hasSpecificPricingRules && (
          <div className={mergeClassName(
            'my-6 flex flex-col',
            compact && 'my-0',
          )}
          >
            { activePricingRules.specific.map(rule => (
              <Rule
                key={rule.id}
                onEdit={() => { onEditRule(rule.id) }}
                onDelete={async () => { await deletePricingRule(rule.id) }}
                compact={compact}
              >
                <PricingRuleItem
                  rule={rule}
                  active={rule.id === currentPricingRuleId}
                />
              </Rule>
            )) }
          </div>
        ) }

        { hasRecurrentPricingRules && (
          <>
            { !compact && (
              <div className="my-4 border-b px-4 font-body2 text-sm text-gray-600">
                { t('pages.companyBranch.availability.ruleList.recurrentRules') }
              </div>
            ) }
            <div
              className={mergeClassName(
                'mb-6 flex flex-col',
                compact && 'mb-0',
              )}
            >
              { activePricingRules.recurrent.map(rule => (
                <Rule
                  key={rule.id}
                  onEdit={() => { onEditRule(rule.id) }}
                  onDelete={async () => { await deletePricingRule(rule.id) }}
                  compact={compact}
                >
                  <PricingRuleItem
                    rule={rule}
                    active={rule.id === currentPricingRuleId}
                  />
                </Rule>
              )) }
            </div>
          </ >
        ) }

        { !hasPricingRules && !compact && (
          <div className="px-3 py-4 text-center text-sm text-gray-400">
            { t('pages.companyBranch.availability.ruleList.noPricingRules') }
          </div>
        ) }

        { monthName
          ? (
            <div className="my-4 border-t px-4 py-2 text-center font-body2 shadow-md">
              { t('pages.companyBranch.availability.ruleList.availabilitiesPeriodTitle', {
                period: monthName,
              }) }
            </div>
            )
          : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              { (hasAvailabilityRules || !compact) && (
                <div className="px-4 pb-1 pt-4 text-base font-bold">
                  { t('pages.companyBranch.availability.ruleList.availabilitiesTitle') }
                </div>
              ) }
            </>
            ) }

        { hasSpecificAvailabilityRules && (
          <div className={mergeClassName(
            'my-6 flex flex-col',
            compact && 'my-0',
          )}
          >
            { activeAvailabilityRules.specific.map(rule => (
              <Rule
                key={rule.id}
                onEdit={() => { onEditRule(rule.id) }}
                onDelete={async () => { await deleteAvailabilityRule(rule.id) }}
                compact={compact}
              >
                <AvailabilityRuleItem rule={rule} />
              </Rule>
            )) }
          </div>
        ) }

        { hasRecurrentAvailabilityRules && (
          <>
            { !compact && (
              <div className="my-4 border-b px-4 font-body2 text-sm text-gray-600">
                { t('pages.companyBranch.availability.ruleList.recurrentRules') }
              </div>
            ) }
            <div className={mergeClassName(
              'mb-6 flex flex-col',
              compact && 'mb-0',
            )}
            >
              { activeAvailabilityRules.recurrent.map(rule => (
                <Rule
                  key={rule.id}
                  onEdit={() => { onEditRule(rule.id) }}
                  onDelete={async () => { await deleteAvailabilityRule(rule.id) }}
                  compact={compact}
                >
                  <AvailabilityRuleItem rule={rule} />
                </Rule>
              )) }
            </div>
          </ >
        ) }

        { !hasAvailabilityRules && !compact && (
          <div className="px-3 py-5 text-center text-sm text-gray-400">
            { t('pages.companyBranch.availability.ruleList.noAvailabilityRules') }
          </div>
        ) }

      </Paper>
    </>
  )
}

export default RuleList
