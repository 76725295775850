import { gql } from '@apollo/client'

import { quotesFields } from '../../adminQuotes/core/quotes.queries'
import { commonMoveDetailsFields } from '../../moves/core/moves.queries'
import { invoiceFields } from '../../invoices/core/invoices.queries'

const moveAgentFields = `
id
firstName
lastName
`

const moveUserFields = `
firstName
lastName
email
phoneNumber {
  number
}
language
`

const lightPlaceFields = `
address {
  city
  region
}
`

const paymentFields = `
id
createdAt
gateway
gatewayId
status
amount {
  price
  currency
}
refundedAmount {
  price
  currency
}
isDeposit
dispute {
  disputeDate
  status
  reason
  fee {
    price
    currency
  }
}
`

const lightMoveFields = `
id
createdAt
locked
agent {
  ${moveAgentFields}
}
platform
movingDate
originTimezone
origin {
  ${lightPlaceFields}
}
destination {
  ${lightPlaceFields}
}
nbMen
nbTrucks
nbQuotes
user {
  ${moveUserFields}
}
quoteSelected {
  customized
  subtotal {
    price
    currency
  }
  companyName
}
quoteAccepted {
  date
}
moveApprovedByAgent
utmSource
utmMedium
utmCampaign
activePromoCode {
  code
  type
  value
}
depositStatus
`

export const moveFields = `
${commonMoveDetailsFields}
id
createdAt
locked
flexibleDate
agent {
  ${moveAgentFields}
}
platform
calendlyScheduledEventUri
selectedBranch
utmSource
utmMedium
utmCampaign
deposit {
  ${paymentFields}
}
paymentProfile {
  depositRequest {
    gateway
    gatewayId
    createdAt
    clientSecret
    amount {
      price
      currency
    }
  }
  paymentMethod {
    gateway
    brand
    lastFourDigits
    expirationMonth
    expirationYear
  }
}
invoices {
  ${invoiceFields}
}
payments {
  ${paymentFields}
}
moveApprovedByAgent
latestJobRequest {
  id
  createdAt
  createdBy {
    firstName
    lastName
  }
  status
  message
  response {
    createdAt
    message
  }
}
user {
  ${moveUserFields}
}
quotes {
  ${quotesFields}
}
`

export const getMoves = gql`
query Admin_getMoves($query: PaginatedQuery, $showPastMoves: Boolean, $customersFilter: MovesCustomersFilterValue, $assignedFilter: MovesAssignedFilterValue, $dateRangeFilter: DateRangeFilter) {
  admin_getMoves(query: $query, showPastMoves: $showPastMoves, customersFilter: $customersFilter, assignedFilter: $assignedFilter, dateRangeFilter: $dateRangeFilter) {
    data {
      ${lightMoveFields}
    }
    count
    hasMore
  }
}
`

export const getMove = gql`
query Admin_getMove($moveId: String!) {
  admin_getMove(moveId: $moveId) {
    ${moveFields}
  }
}
`
