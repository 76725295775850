import { useTranslation } from 'react-i18next'
import UserIcon from '@mui/icons-material/PersonOutlined'

import { type JobRequestForPartner } from '../../../modules/jobRequests'
import Paper from '../../../components/Paper'
import CustomerDetails from '../../../components/move/CustomerDetails'

type CustomerProps = {
  jobRequest: JobRequestForPartner
}

const Customer: React.FC<CustomerProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()
  return (
    <Paper>
      <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
        <UserIcon />
        { ' ' }
        { t('pages.jobRequest.sections.customer') }
      </h2>
      <CustomerDetails move={jobRequest.move} />
    </Paper>
  )
}

export default Customer
