import { gql } from '@apollo/client'

export const getRoles = gql`
query getRoles {
  getRoles {
    permissions
    key
    name
    description
    parent
  }
}
`
