import { type Amount } from '../../common/amount'

const DEFAULT_LOCALE = 'en-US'

type FormatPriceOptions = {
  compact?: boolean
  showDecimals?: boolean
  showCurrency?: boolean
}

export const formatPrice = (
  amount: Amount,
  {
    compact = false,
    showDecimals = true,
    showCurrency = false,
  }: FormatPriceOptions = {},
) => {
  const priceFormatter = new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currency: amount.currency,
  })

  const price = showDecimals ? amount.price : Math.ceil(amount.price)

  const isMinus = price < 0
  const formattedPrice = priceFormatter.format(price).split('$')[1]
  const parts = formattedPrice.split('.')

  return [
    showCurrency ? amount.currency : '',
    showCurrency && !compact ? ' ' : '',
    isMinus ? '-' : '',
    !compact ? ' ' : '',
    '$', // todo : use correct currency symbol
    !compact ? ' ' : '',
    parts[0],
    showDecimals ? `.${parts[1]}` : '',
  ].join('')
}
