import { useTranslation } from 'react-i18next'
import CouponIcon from '@mui/icons-material/LocalOfferOutlined'

import {
  type Quote,
  useQuoteConstraints,
  useCheaperDate,
  useCheaperPrice,
} from '../../../../modules/quotes'
import {
  type PromoCode,
  useQuoteSubtotalWithPromoCode,
} from '../../../../modules/adminPromoCodes'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { useDateFormatter } from '../../../../utils/date'
import Tooltip from '../../../Tooltip'
import Price from '../../../Price'
import QuoteConstraints from '../QuoteConstraints'
import QuoteIdentification from '../QuoteIdentification'

type QuoteHeaderProps = {
  quote: Quote
  open: boolean
  promoCode?: PromoCode
  presentationMode?: boolean
  highlighted?: boolean
}

const QuoteHeader: React.FC<QuoteHeaderProps> = ({
  quote,
  open,
  promoCode,
  presentationMode = false,
  highlighted = false,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const cheaperDate = useCheaperDate(quote)
  const cheaperPrice = useCheaperPrice(quote, promoCode)
  const cheaperPriceDiff = cheaperPrice ? Math.floor(quote.subtotal.price - cheaperPrice.price) : 0
  const constraints = useQuoteConstraints(quote)
  const hasConstraints = !!constraints && (constraints?.length ?? 0) > 0
  const quoteSubtotalWithPromoCode = useQuoteSubtotalWithPromoCode(quote, promoCode)

  return (
    <div className="flex flex-col-reverse md:flex-row md:justify-between">

      <QuoteIdentification
        quote={quote}
        small={open}
        className={mergeClassName(
          !open && 'lg:mb-[-64px]',
        )}
      />

      <div className="flex items-center justify-between sm:justify-end md:flex-row md:justify-center md:gap-4">

        <div className="ml-6 flex items-center gap-2 sm:ml-0">
          { hasConstraints && (
            <div className={mergeClassName(
              // 'pb-2 pr-2 text-right md:w-auto md:p-0 md:text-left',
              open && 'hidden lg:block',
            )}
            >
              <QuoteConstraints
                quote={quote}
                compact
              />
            </div>
          ) }

          { cheaperPrice && cheaperDate && (
            <Tooltip
              tooltip={(
                <div className="p-4 font-sans text-base text-green-700">

                  { t('quotes.quote.cheapestPrice.saveMoney') }
                  { ' ' }
                  <strong>{ cheaperPriceDiff }$</strong>
                  <br />
                  { t('quotes.quote.cheapestPrice.byChangingDate') }
                  { ' ' }
                  <strong>{ formatDate(cheaperDate, { showYear: false }) }</strong>
                  !
                </div>
              )}
              className="bg-green-50"
            >
              { !presentationMode && (
                <div className={mergeClassName(
                  'flex items-center gap-2 rounded-md bg-green-50 py-1 pl-2 pr-4 font-sans text-sm leading-tight text-green-700',
                  'hidden lg:flex',
                  hasConstraints && 'hidden lg:flex',
                )}
                >
                  <CouponIcon className="rotate-90 !text-[18px]" />
                  <div>
                    { t('quotes.quote.cheapestPrice.from') }
                    <br />
                    <span className="font-bold">
                      <Price amount={cheaperPrice} showDecimals={false} />
                    </span>
                  </div>
                </div>
              ) }
            </Tooltip>
          ) }
        </div>

        <div className="py-6 pl-2 pr-4 md:pl-0 md:pr-6">
          <div className="flex items-center">

            <div className={mergeClassName(
              'flex items-center gap-x-8 font-body2 text-2xl sm:text-3xl md:text-4xl text-neutral-500 w-full md:min-w-[150px] justify-end whitespace-nowrap',
              highlighted && 'text-gray-700',
            )}
            >
              { promoCode && quoteSubtotalWithPromoCode && (
                <div className="text-2xl text-neutral-300 child:line-through">
                  <Price amount={quote.subtotal} />
                </div>
              ) }

              <Price amount={quoteSubtotalWithPromoCode ?? quote.subtotal} />
            </div>

          </div>
          <div className="flex items-end justify-end gap-1 pr-5 text-sm ">
            <div className="text-xs font-bold uppercase text-slate-400">
              { t('quotes.quote.estimate') }
            </div>
            <div className="whitespace-nowrap text-slate-400">
              ({ t('quotes.quote.plusTaxes') })
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteHeader
