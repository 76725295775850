import { mergeClassName } from '../../../utils/mergeClassName'
import PageContent from '../PageContent/PageContent'

type PageTitleProps = {
  children?: React.ReactNode
  className?: string
  wide?: boolean
  fullWidth?: boolean
  contrastBackground?: boolean
}

const PageTitle: React.FC<PageTitleProps> = ({
  children,
  className,
  wide = false,
  fullWidth = false,
  contrastBackground = false,
}) => {
  return (
    <div className={mergeClassName(
      'lg:mb-8 border-b border-zinc-200',
      'bg-gradient-to-tl from-zinc-50/80 to-[#fa372c]/[0.03]',
      contrastBackground && 'bg-gradient-to-tl from-zinc-100 to-[#fa372c]/5',
      'flex grow w-full lg:min-h-[165px] items-center',
      className,
    )}
    >
      <div className={mergeClassName(
        'py-8 lg:py-12 w-full',
        wide && 'child:max-w-[1850px]',
        fullWidth && 'child:max-w-none px-6',
      )}
      >
        <PageContent>
          <h1 className="text-center font-body2 text-4xl lg:text-left">
            { children }
          </h1>
        </PageContent>
      </div>
    </div>
  )
}

export default PageTitle
