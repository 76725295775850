import { type DateHeaderProps } from 'react-big-calendar'
import IconButton from '@mui/material/IconButton'
import PlusIcon from '@mui/icons-material/AddCircleRounded'

const DateCell: React.FC<DateHeaderProps> = ({ label }) => {
  return (
    <>
      <div className="text-center">
        <span className="calendar-date text-xs md:text-sm">
          { label }
        </span>
      </div>
      <div className="absolute inset-y-0 w-[14.285%] cursor-pointer hover:bg-slate-300/30 child:hover:block">
        <div className="plus-icon absolute bottom-0 right-0 hidden">
          <IconButton>
            <PlusIcon />
          </IconButton>
        </div>
      </div>
    </>
  )
}

export default DateCell
