import { useTranslation } from 'react-i18next'

import { type FullMove } from '../../../modules/adminMoves'
import { type FullMoveForPartner, useMoveState } from '../../../modules/moves'
import { useDateFormatter } from '../../../utils/date'
import Tabs, { Tab } from '../../../components/Tabs'
import AddButtonWrapper from './AddButtonWrapper'

type JobsTabsProps = {
  move: FullMove | FullMoveForPartner
  addButton?: React.ReactNode
}

const JobsTabs: React.FC<JobsTabsProps> = ({
  move,
  addButton,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const {
    moveDate,
    nbMen,
    totalNbTrucks,
  } = useMoveState().forMove(move)

  return (
    <Tabs
      value={0}
    >
      <Tab
        value={0}
        label={(
          <div className="text-left">
            { t('move.jobs.moving') }
            { ': ' }
            { t('nbMen', { count: nbMen }) }
            { ' + ' }
            { t('nbTrucks', { count: totalNbTrucks }) }
            <br />
            <div className="font-sans text-sm font-bold text-gray-400">
              { formatDate(moveDate) }
            </div>
          </div>
        )}
      />

      { addButton && (
        <AddButtonWrapper>
          { addButton }
        </AddButtonWrapper>
      ) }

    </Tabs>
  )
}

export default JobsTabs
