import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, Transition } from '@headlessui/react'
import DashboardIcon from '@mui/icons-material/SpaceDashboardOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import AvailabilityIcon from '@mui/icons-material/CalendarMonthOutlined'
import JobRequestIcon from '@mui/icons-material/MoveToInboxOutlined'
import CompaniesIcon from '@mui/icons-material/BusinessOutlined'
import UsersIcon from '@mui/icons-material/AccountCircleOutlined'
import MoveIcon from '@mui/icons-material/PermContactCalendarOutlined'
import QuotesIcon from '@mui/icons-material/ScienceOutlined'
import ProspectsIcon from '@mui/icons-material/HeadsetMicOutlined'
import PromoCodesIcon from '@mui/icons-material/LocalOfferOutlined'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { Permission, useAuthenticationState, useCurrentUserAccountType, usePermission } from '../../../modules/users'
// import DarkModeSwitcher from '../../../modules/theme/ui/DarkModeSwitcher'
import MobileMenuProfile from './MobileMenuProfile'
import MobileMenuLink from './MobileMenuLink'
import InstallPwaMobileLink from './InstallPwaMobileLink'
import { LanguageSelector } from '../../../modules/i18n'
import Route from '../../../app/Route.enum'
import { getCompanyBranchRouteRegExp, useCurrentCompanyBranchName, useOnBehalfState } from '../../../modules/companies'

const TopSection = styled(Box)(({ theme }) => ({
  paddingTop: '0.75rem',
  '& a:last-of-type': {
    border: 'none',
  },
}))

const BottomSection = styled(Box)(({ theme }) => ({
  paddingBottom: '0.75rem',
  '& a:last-of-type': {
    border: 'none',
  },
}))

type MobileMenuProps = {
  open: boolean
  onClose: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthenticationState()
  const { isAdmin } = useCurrentUserAccountType()
  const branchName = useCurrentCompanyBranchName()
  const canManageOwnJobRequests = usePermission(Permission.canManageOwnJobRequests)
  const canReadUsers = usePermission(Permission.canReadUsers)
  const canReadMoves = usePermission(Permission.canReadMoves)
  const canReadCompanies = usePermission(Permission.canReadCompanies)
  const canManageProspects = usePermission(Permission.canManageProspects)
  const canGenerateDetailedQuotes = usePermission(Permission.canGenerateDetailedQuotes)
  const canAccessPartnerDashboard = usePermission(Permission.canManageCompanies)
  const canReadPromoCodes = usePermission(Permission.canReadPromoCodes)

  let { onBehalf } = useOnBehalfState()
  onBehalf &&= canAccessPartnerDashboard

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed top-[60px] z-50 lg:hidden"
        onClose={onClose}
      >
        { /* backdrop */ }
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-[60px] bg-gray-600/75 backdrop-blur-sm" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog.Panel className="fixed right-0 inline-flex h-[calc(100dvh-60px)] w-[360px] max-w-[90vw] overflow-y-auto rounded-l-md border-t bg-white">

            <div
              className="flex h-full grow flex-col justify-between"
              data-testid="mobile-menu"
            >
              <TopSection>
                <div className="flex items-center justify-end border-b pb-3 pr-4">
                  <LanguageSelector onClick={onClose} className="ml-5" />
                  { /* <DarkModeSwitcher /> */ }
                </div>

                { onBehalf && (
                  <div className="pl-2 pt-2 text-sm text-neutral-400">
                    { t('header.mobileMenu.adminOptions') }
                  </div>
                ) }
                { isAdmin && (
                  <>
                    <MobileMenuLink
                      to={Route.Dashboard}
                      Icon={DashboardIcon}
                      exact
                    >
                      { t('menu.admin.dashboard') }
                    </MobileMenuLink>

                    { canReadCompanies && (
                      <MobileMenuLink
                        to={Route.Companies}
                        Icon={CompaniesIcon}
                        exact
                      >
                        { t('menu.admin.companies') }
                      </MobileMenuLink>
                    ) }

                    { canReadUsers && (
                      <MobileMenuLink
                        to={Route.AdminUsers}
                        Icon={UsersIcon}
                      >
                        { t('menu.admin.users') }
                      </MobileMenuLink>
                    ) }

                    { canReadMoves && (
                      <MobileMenuLink
                        to={Route.AdminMoves}
                        Icon={MoveIcon}
                      >
                        { t('menu.admin.moves') }
                      </MobileMenuLink>
                    ) }

                    { canManageProspects && (
                      <MobileMenuLink
                        to={Route.Prospects}
                        Icon={ProspectsIcon}
                      >
                        { t('menu.admin.prospects') }
                      </MobileMenuLink>
                    ) }

                    { canGenerateDetailedQuotes && (
                      <MobileMenuLink
                        to={Route.GetQuotes}
                        Icon={QuotesIcon}
                      >
                        { t('menu.admin.getQuotes') }
                      </MobileMenuLink>
                    ) }

                    { canReadPromoCodes && (
                      <MobileMenuLink
                        to={Route.PromoCodes}
                        Icon={PromoCodesIcon}
                      >
                        { t('menu.admin.promoCodes') }
                      </MobileMenuLink>
                    ) }
                  </>
                ) }

                { onBehalf && branchName && (
                  <div className="my-3 pl-2 pt-2 text-sm text-neutral-400">
                    { t('header.onBehalfOf') } { branchName }
                  </div>
                ) }

                { (!isAdmin || onBehalf) && (
                  <>
                    <MobileMenuLink
                      to={onBehalf ? Route.CompaniesOnBehalf : Route.Dashboard}
                      Icon={DashboardIcon}
                      exact
                    >
                      { t('menu.partner.dashboard') }
                    </MobileMenuLink>

                    <MobileMenuLink
                      to={Route.BranchSettingsLandingPage}
                      Icon={TruckIcon}
                      matchRegex={getCompanyBranchRouteRegExp(Route.BranchSettings)}
                    >
                      { t('menu.partner.branches') }
                    </MobileMenuLink>

                    <MobileMenuLink
                      to={Route.BranchAvailabilityLandingPage}
                      Icon={AvailabilityIcon}
                      matchRegex={getCompanyBranchRouteRegExp(Route.BranchAvailability)}
                    >
                      { t('menu.partner.availability') }
                    </MobileMenuLink>

                    { canManageOwnJobRequests && (
                      <MobileMenuLink
                        to={Route.JobRequestsLandingPage}
                        Icon={JobRequestIcon}
                        matchRegex={getCompanyBranchRouteRegExp(Route.JobRequests)}
                      >
                        { t('menu.partner.jobRequests') }
                      </MobileMenuLink>
                    ) }
                  </>
                ) }

              </TopSection>

              <BottomSection>
                { isAuthenticated
                  ? (
                    <MobileMenuProfile />
                    )
                  : (
                    <>
                      <MobileMenuLink to={Route.Login}>
                        { t('header.signIn') }
                      </MobileMenuLink>

                      <MobileMenuLink to={Route.SignUp}>
                        { t('header.signUp') }
                      </MobileMenuLink>

                      <InstallPwaMobileLink>{ t('header.mobileMenu.install') }</InstallPwaMobileLink>
                    </>
                    ) }
              </BottomSection>
            </div>

          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export default MobileMenu
