import { useTranslation } from 'react-i18next'
import MessageIcon from '@mui/icons-material/ChatOutlined'

import { type JobRequestForPartner } from '../../../modules/jobRequests'
import Paper from '../../../components/Paper'
import ExpandableMessage from '../../../components/ExpandableMessage'

type AgentMessageProps = {
  jobRequest: JobRequestForPartner
}

const AgentMessage: React.FC<AgentMessageProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()
  if (!jobRequest.message) {
    return null
  }

  return (
    <Paper>
      <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
        <MessageIcon />
        { ' ' }
        { t('pages.jobRequest.sections.agentMessage') }
      </h2>

      <ExpandableMessage
        message={jobRequest.message}
        showAll
      />
    </Paper>
  )
}

export default AgentMessage
