import { useTranslation } from 'react-i18next'

import Route from '../../../app/Route.enum'
import { Permission, usePermission } from '../../../modules/users'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import { usePendingRequestsNotifications } from '../../../modules/jobRequests'
import { JobRequestItem } from '../../../components/jobRequest'
import Spinner from '../../../components/Spinner'
import Link, { LinkVariant } from '../../../components/Link'
import Paper from '../../../components/Paper'

const PendingJobRequests: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentcompanyBranch } = useCurrentCompanyBranch()
  const canManageOwnJobRequests = usePermission(Permission.canManageOwnJobRequests)
  const jobRequests = usePendingRequestsNotifications()

  const widgetEnabled = canManageOwnJobRequests && currentcompanyBranch?.approved

  const total = jobRequests?.count ?? 0
  const onScreen = jobRequests?.data.length ?? 0
  const other = total - onScreen

  if (!widgetEnabled) {
    return null
  }

  return (
    <>
      <div className="flex flex-col justify-between lg:flex-row lg:pr-4">
        <h2 className="mb-4 flex items-center gap-2 font-body2 text-lg font-semibold">

          { t('pages.partnerDashboard.pendingJobRequests.title') }
          { jobRequests && jobRequests?.count > 0 && (
            <>
              { ' ' }
              ({ jobRequests.count })
            </ >
          ) }
        </h2>

        <Link
          to={Route.JobRequestsLandingPage}
          variant={LinkVariant.Primary}
        >
          { t('actions.showAll') }
        </Link>

      </div>

      { (!jobRequests) && <Spinner /> }

      { jobRequests?.data?.map((jobRequest, index) => (
        <JobRequestItem
          key={jobRequest.id}
          jobRequest={jobRequest}
          compact
        />
      )) }

      { other > 0 && (
        <Link
          to={Route.JobRequestsLandingPage}
          variant={LinkVariant.Underlined}
        >
          { t('pages.partnerDashboard.pendingJobRequests.plusOther', { count: other }) }
        </Link>
      ) }

      { jobRequests?.count === 0 && (
        <Paper>
          <em>
            { t('pages.partnerDashboard.pendingJobRequests.noPendingRequests') }
          </em>
        </Paper>
      ) }
    </>
  )
}

export default PendingJobRequests
