/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { keyValueMapToRecord, recordToKeyValueMap } from '../../../../common/keyValueMap'
import { type CompanyBranchLegal } from '../../../../modules/companies'
import { type LegalFormValues, type TaxesFormValues } from './LegalForm.type'

export const CompanyBranchLegalToFormData = (companyBranchLegal: CompanyBranchLegal): LegalFormValues => {
  return {
    ...companyBranchLegal,
    taxes: keyValueMapToRecord(
      companyBranchLegal.taxes,
    ),
    insurances: {
      commercialLiabilityCoverageLimit: String(companyBranchLegal.insurances.commercialLiabilityCoverageLimit),
      cargoCoverageLimit: String(companyBranchLegal.insurances.cargoCoverageLimit),
    },
  }
}

const filterEmptyTaxValues = (value: TaxesFormValues) => {
  return Object.fromEntries(Object.entries(value).filter(([_, value]) => !!value)) as TaxesFormValues
}

export const formatFormOutput = (formData: LegalFormValues): CompanyBranchLegal => {
  return {
    ...formData,
    companyAddress: {
      ...formData.companyAddress,
      apartment: formData.companyAddress.apartment !== ''
        ? formData.companyAddress.apartment
        : undefined,
    },
    taxes: recordToKeyValueMap(
      filterEmptyTaxValues(formData.taxes),
    ),
    insurances: {
      commercialLiabilityCoverageLimit: parseInt(formData.insurances.commercialLiabilityCoverageLimit),
      cargoCoverageLimit: parseInt(formData.insurances.cargoCoverageLimit),
    },
    owner: {
      ...formData.owner,
      phoneNumber: {
        number: formData.owner.phoneNumber!.number,
        extension: formData.owner.phoneNumber!.extension,
      },
    },
  }
}
