import { useTranslation } from 'react-i18next'
import DownloadIcon from '@mui/icons-material/SimCardDownloadOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import { useDateFormatter } from '../../../../../utils/date'
import { formatPrice } from '../../../../../utils/price'
import Table, {
  Column,
  Row,
  Cell,
} from '../../../../../components/Table'
import Link, {
  LinkVariant,
} from '../../../../../components/Link'
import { useGetInvoiceLink } from '../../../../../modules/invoices'
import Language from '../../../../../app/Language.enum'

type InvoicesTableProps = {
  move: FullMove
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const getInvoiceLink = useGetInvoiceLink()

  if (move.invoices.length === 0) {
    return (
      <div className="pb-4 text-center text-neutral-500">
        { t('invoice.table.empty') }
      </div>
    )
  }

  return (
    <Table
      columns={(
        <>
          <Column
            className="min-w-[275px]"
          >
            { t('invoice.table.columns.createdAt') }
          </Column>

          <Column expand>
            { t('invoice.table.columns.object') }
          </Column>

          <Column
            right
            className="min-w-[200px]"
          >
            { t('invoice.table.columns.subtotal') }
          </Column>

          <Column
            right
            className="min-w-[200px]"
          >
            { t('invoice.table.columns.total') }
          </Column>
        </>
      )}
      className="lg:max-w-[calc(100vw-200px)]"
    >
      { move.invoices.map(invoice => {
        return (
          <Row key={invoice.id}>
            <Cell noWrap>
              { formatDate(invoice.createdAt, { showTime: true }) }
            </Cell>

            <Cell noWrap>
              <strong>
                { invoice.invoiceNumber }
              </strong>
              <span className="text-sm text-neutral-500">
                { ' ( ' }
                { t(`invoice.table.category.${invoice.category}`) }
                { ' )' }
              </span>

              <div className="flex gap-x-8 text-sm text-neutral-400">
                <Link
                  to={getInvoiceLink(invoice, { moveId: move.id, language: Language.En })}
                  variant={LinkVariant.Underlined}
                  target="_blank"
                  className="inline-flex gap-x-1"
                >
                  { t('invoice.table.downloadInvoiceEn') }
                  <DownloadIcon className="!text-[20px]" />
                </Link>

                <Link
                  to={getInvoiceLink(invoice, { moveId: move.id, language: Language.Fr })}
                  variant={LinkVariant.Underlined}
                  target="_blank"
                  className="inline-flex gap-x-1"
                >
                  { t('invoice.table.downloadInvoiceFr') }
                  <DownloadIcon className="!text-[20px]" />
                </Link>
              </div>
            </Cell>

            <Cell
              right
              noWrap
            >
              <div className={mergeClassName(
                invoice.subtotal.price < 0 && 'text-red-400',
              )}
              >
                { formatPrice(invoice.subtotal) }
              </div>
            </Cell>

            <Cell
              right
              noWrap
            >
              <div className={mergeClassName(
                'font-bold',
                invoice.total.price < 0 && 'text-red-400',
              )}
              >
                { formatPrice(invoice.total, { showCurrency: true }) }
              </div>
            </Cell>
          </Row>
        )
      }) }
    </Table>
  )
}

export default InvoicesTable
