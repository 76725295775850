import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import StatusIcon from '@mui/icons-material/FilterAltOutlined'
import AssigneeIcon from '@mui/icons-material/HeadsetMicOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import RemoveIcon from '@mui/icons-material/HighlightOffOutlined'

import {
  CustomersFilterValue,
  AssignedFilterValue,
} from '../../../modules/adminMoves/core/adminMoves.types'
import { useDateFormatter } from '../../../utils/date'
import { mergeClassName } from '../../../utils/mergeClassName'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuItem,
} from '../../../components/DropDownMenu'
import { type CustomFiltersProps } from '../../../components/dataGrid'
import DateRangeModal, { type DateRange } from './DateRangeModal'

export const PAST_MOVES_FILTER = 'showPastMoves'
export const CUSTOMERS_FILTER = 'customersFilter'
export const ASSIGNED_FILTER = 'assignedFilter'
export const DATE_RANGE_FILTER = 'dateRangeFilter'

const parseDateRangeValues = (values: any) => {
  if (!values?.from) {
    return
  }
  return {
    from: new Date(values.from),
    to: values.to ? new Date(values.to) : undefined,
  }
}

/**
 * custom filters to render on top of the DataGrid component
 */
const CustomFilters: React.FC<CustomFiltersProps> = ({
  values,
  setValues,
  disabled,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)

  const showPastMoves: boolean = values[PAST_MOVES_FILTER] ?? true

  const customersFilter: CustomersFilterValue = values[CUSTOMERS_FILTER] ?? CustomersFilterValue.All
  const hasCustomersFilter = customersFilter !== CustomersFilterValue.All

  const assignedFilter: AssignedFilterValue = values[ASSIGNED_FILTER] ?? AssignedFilterValue.All
  const hasAssignedFilter = assignedFilter !== AssignedFilterValue.All

  const dateRangeFilter: DateRange | undefined = parseDateRangeValues(values[DATE_RANGE_FILTER])
  const hasDateRangeFilter = !!dateRangeFilter?.from

  const dateRangeLabel = (() => {
    if (!dateRangeFilter) {
      return
    }
    if (dateRangeFilter.from && !dateRangeFilter.to) {
      return formatDate(dateRangeFilter.from, { showYear: false })
    }

    return (
      <>
        { formatDate(dateRangeFilter.from, { showYear: false }) }
        { ' ' }
        { t('date.to') }
        { ' ' }
        { formatDate(dateRangeFilter.to, { showYear: false }) }
      </>
    )
  })()

  const nbFiltersApplied = (() => {
    let nb = 0
    if (hasCustomersFilter) {
      nb++
    }
    if (hasAssignedFilter) {
      nb++
    }
    if (hasDateRangeFilter) {
      nb++
    }
    return nb
  })()

  const handleResetFilters = () => {
    setValues({})
  }

  const onPastMovesClick = () => {
    setValues({
      ...values,
      [PAST_MOVES_FILTER]: !showPastMoves,
    })
  }

  const onCustomersFilterChange = (value: string) => {
    setValues({
      ...values,
      [CUSTOMERS_FILTER]: value,
    })
  }

  const onAssignedFilterChange = (value: string) => {
    setValues({
      ...values,
      [ASSIGNED_FILTER]: value,
    })
  }

  const handleEditDateRange = () => {
    setShowDateRangePicker(true)
  }

  const handleRemoveDateRange = () => {
    setValues({
      ...values,
      [DATE_RANGE_FILTER]: undefined,
    })
  }

  const setDateRangeFilter = (dateRange: DateRange) => {
    setShowDateRangePicker(false)
    setValues({
      ...values,
      [DATE_RANGE_FILTER]: dateRange,
    })
  }

  return (
    <>
      <DateRangeModal
        open={showDateRangePicker}
        onClose={() => { setShowDateRangePicker(false) }}
        onSelect={setDateRangeFilter}
        currentRange={dateRangeFilter}
      />
      <div className="hidden items-center gap-4 md:flex">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>

            <Button
              variant="outlined"
              color="secondary"
              endIcon={<KeyboardArrowDownIcon />}
              className="!normal-case"
              disableFocusRipple
            >
              { nbFiltersApplied > 0
                ? (
                  <strong>
                    { t('pages.admin.moves.table.filters.customersFilters.titleActive', { count: nbFiltersApplied }) }
                  </strong>
                  )
                : t('pages.admin.moves.table.filters.customersFilters.title') }
            </Button>

          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" sideOffset={8}>

            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <div className={mergeClassName(
                  hasCustomersFilter && 'font-bold',
                )}
                >
                  <StatusIcon className="mr-2 !text-[18px] text-gray-400" />
                  { t('pages.admin.moves.table.filters.customersFilters.status.title') }
                </div>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {
                Object.values(CustomersFilterValue).map(filter => (
                  <Fragment key={filter}>
                    <DropdownMenuCheckboxItem
                      checked={customersFilter === filter}
                      onCheckedChange={() => { onCustomersFilterChange(filter) }}
                    >
                      { t(`pages.admin.moves.table.filters.customersFilters.status.${filter}`) }
                    </DropdownMenuCheckboxItem>
                    { filter === CustomersFilterValue.All && (
                      <DropdownMenuSeparator />
                    ) }
                  </Fragment>
                ))
              }
              </DropdownMenuSubContent>
            </DropdownMenuSub>

            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <div className={mergeClassName(
                  hasAssignedFilter && 'font-bold',
                )}
                >
                  <AssigneeIcon className="mr-2 !text-[18px] text-gray-400" />
                  { t('pages.admin.moves.table.filters.customersFilters.assignedTo.title') }
                </div>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>

                {
                Object.values(AssignedFilterValue).map(filter => (
                  <Fragment key={filter}>
                    <DropdownMenuCheckboxItem
                      checked={assignedFilter === filter}
                      onCheckedChange={() => { onAssignedFilterChange(filter) }}
                    >
                      { t(`pages.admin.moves.table.filters.customersFilters.assignedTo.${filter}`) }
                    </DropdownMenuCheckboxItem>
                    { filter === AssignedFilterValue.All && (
                      <DropdownMenuSeparator />
                    ) }
                  </Fragment>
                ))
              }
              </DropdownMenuSubContent>
            </DropdownMenuSub>

            { dateRangeLabel
              ? (
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <div className="font-bold">
                      <CalendarIcon className="mr-2 !text-[18px] text-gray-400" />
                      { dateRangeLabel }
                    </div>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem onClick={handleEditDateRange}>
                      <EditIcon className="mr-2 !text-[18px]" />
                      { t('actions.edit') }
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={handleRemoveDateRange}
                      className="text-red-700 child:text-red-700"
                    >
                      <RemoveIcon className="mr-2 !text-[18px]" />
                      { t('actions.remove') }
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuSub>
                )
              : (
                <DropdownMenuItem
                  onClick={handleEditDateRange}
                  className="pr-10"
                >
                  <div>
                    <CalendarIcon className="mr-2 !text-[18px] text-gray-400" />
                    { t('pages.admin.moves.table.filters.dateRange.addDateRange') }
                  </div>
                </DropdownMenuItem>
                ) }

            { nbFiltersApplied > 0 && (
              <>
                <DropdownMenuSeparator />

                <DropdownMenuItem
                  onClick={handleResetFilters}
                  className="text-red-700 child:text-red-700"
                >
                  <RemoveIcon className="mr-2 !text-[18px]" />
                  { t('pages.admin.moves.table.filters.resetFilters') }
                </DropdownMenuItem>
              </>
            ) }

          </DropdownMenuContent>

        </DropdownMenu>

        <FormControlLabel
          control={(
            <Switch
              checked={showPastMoves}
              onClick={onPastMovesClick}
              disabled={disabled}
            />
          )}
          label={t('pages.admin.moves.table.filters.showPastMoves')}
        />
      </div>
    </>
  )
}

export default CustomFilters
