import { useTranslation } from 'react-i18next'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import { type JobRequestForPartner } from '../../../modules/jobRequests'
import Paper from '../../../components/Paper'
import JobDetails from '../../../components/move/JobDetails'

type JobProps = {
  jobRequest: JobRequestForPartner
}

const Job: React.FC<JobProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()

  return (
    <Paper className="rounded-tl-none">
      <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
        <TruckIcon />
        { ' ' }
        { t('pages.jobRequest.sections.move') }
      </h2>

      <JobDetails move={jobRequest.move} />
    </Paper>
  )
}

export default Job
