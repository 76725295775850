/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import RevenueIcon from '@mui/icons-material/PaidOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import AverageIcon from '@mui/icons-material/PercentOutlined'

import {
  cumulativeData,
  useAdminDashboardMetrics,
} from '../../../../modules/dashboard'
import { Currency } from '../../../../common/amount'
import { formatNumber } from '../../../../utils/number'
import { formatPrice } from '../../../../utils/price'
import {
  Cards,
  Card,
  CardTitle,
  CardMetric,
  CardMetricDiff,
} from '../../../../components/card'
import Tooltip from '../../../../components/Tooltip'
import AcceptedQuotesValueChart from './AcceptedQuotesValueChart'
import { useRevenue } from './useRevenue'
import { useRevenueDiff } from './useRevenueDiff'

const AcceptedQuotesValue: React.FC = () => {
  const { t } = useTranslation()
  const [monthlyView, setMonthlyView] = useState(true)
  const { data: dashboardMetrics } = useAdminDashboardMetrics()

  const movesMetrics = dashboardMetrics?.moves
  const acceptedQuotesRawValue = monthlyView ? movesMetrics?.lastMonthAcceptedQuotesValue : movesMetrics?.lastYearAcceptedQuotesValue
  const previousMonth = monthlyView ? movesMetrics?.previousMonthAcceptedQuotesValue : undefined

  const acceptedQuotesValue = useRevenue(acceptedQuotesRawValue, monthlyView)
  const valueDiff = useRevenueDiff(acceptedQuotesValue, previousMonth)

  if (!acceptedQuotesValue) {
    return null
  }

  return (
    <div>
      <h2 className="mt-5 flex items-center gap-x-4 text-xl leading-6 text-gray-900">

        { t('pages.admin.dashboard.acceptedQuotes.title') }

        <ToggleButtonGroup
          size="small"
          exclusive
          value={monthlyView}
          onChange={() => { setMonthlyView(!monthlyView) }}
        >
          <ToggleButton
            value
            disabled={monthlyView}
            className="!py-1 !capitalize"
          >
            { t('pages.admin.dashboard.acceptedQuotes.30Days') }
          </ToggleButton>

          <ToggleButton
            value={false}
            disabled={!monthlyView}
            className="!py-1 !capitalize"
          >
            { t('pages.admin.dashboard.acceptedQuotes.12Months') }
          </ToggleButton>
        </ToggleButtonGroup>
      </h2>

      <Cards className="sm:grid-cols-1">
        <Card className="min-h-[350px] lg:col-span-3 xl:p-8">
          <AcceptedQuotesValueChart
            data={acceptedQuotesValue.revenueHistory}
            monthlyView={monthlyView}
          />
        </Card>

        <div className="flex flex-col gap-6">
          <Card
            icon={<RevenueIcon />}
            history={cumulativeData(acceptedQuotesValue.revenueHistory)}
          >
            <CardTitle>
              { t('pages.admin.dashboard.acceptedQuotes.acceptedQuotesValue') }
            </CardTitle>
            <CardMetric>
              { formatPrice({
                price: acceptedQuotesValue.totalRevenue,
                currency: Currency.CAD,
              },
              {
                showDecimals: false,
              },
              ) }

              { previousMonth && (
                <Tooltip
                  tooltip={(
                    <>
                      { t('pages.admin.dashboard.acceptedQuotes.comparedTo') }
                      <strong>
                        { formatPrice(
                          {
                            price: previousMonth.revenue,
                            currency: Currency.CAD,
                          },
                          {
                            showDecimals: false,
                          },
                        ) }
                      </strong>
                    </>
                  )}
                  arrow
                >
                  <CardMetricDiff
                    diff={valueDiff?.revenuePercent}
                  />
                </Tooltip>
              ) }

            </CardMetric>
          </Card>

          <Card
            icon={<TruckIcon />}
            history={cumulativeData(acceptedQuotesValue.countHistory)}
          >
            <CardTitle>
              { t('pages.admin.dashboard.acceptedQuotes.acceptedQuotes') }
            </CardTitle>
            <CardMetric>
              { formatNumber(acceptedQuotesValue.count) }
              { previousMonth && (
                <Tooltip
                  tooltip={(
                    <>
                      { t('pages.admin.dashboard.acceptedQuotes.comparedTo') }
                      <strong>{ formatNumber(previousMonth.count) }</strong>
                    </>
                  )}
                  arrow
                >
                  <CardMetricDiff
                    diff={valueDiff?.countPercent}
                  />
                </Tooltip>
              ) }

            </CardMetric>
          </Card>

          <Card
            icon={<AverageIcon />}
          >
            <CardTitle>
              { t('pages.admin.dashboard.acceptedQuotes.averageMoveValue') }
            </CardTitle>
            <CardMetric>
              { formatPrice({ price: acceptedQuotesValue.average, currency: Currency.CAD }, { showDecimals: false }) }

              { previousMonth?.count && (
                <Tooltip
                  tooltip={(
                    <>
                      { t('pages.admin.dashboard.acceptedQuotes.comparedTo') }
                      <strong>
                        { formatPrice(
                          {
                            price: previousMonth.revenue / previousMonth.count,
                            currency: Currency.CAD,
                          },
                          {
                            showDecimals: false,
                          },
                        ) }
                      </strong>
                    </>
                  )}
                  arrow
                >
                  <CardMetricDiff
                    diff={valueDiff?.averagePercent}
                  />
                </Tooltip>
              ) }
            </CardMetric>
          </Card>
        </div>
      </Cards>
    </div>
  )
}

export default AcceptedQuotesValue
