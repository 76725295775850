import { type DateCellWrapperProps } from 'react-big-calendar'

import { CalendarAvailabilityRange } from './RuleCalendar.type'
import { mergeClassName } from '../../utils/mergeClassName'
import useDateAvailability from './useDateAvailability'
import useDateContent from './useDateContent'

const DateCellBg: React.FC<DateCellWrapperProps> = ({
  value,
  children,
}) => {
  const { unavailable } = useDateAvailability(value)
  const content = useDateContent(value)

  return (
    <div
      className={mergeClassName(
        'rbc-day-bg flex relative',
        unavailable === CalendarAvailabilityRange.Am && 'child:bg-gradient-to-t child:from-transparent child:from-50% child:to-red-100/70 child:to-50%',
        unavailable === CalendarAvailabilityRange.Pm && 'child:bg-gradient-to-b child:from-transparent child:from-50% child:to-red-100/70 child:to-50%',
        unavailable === CalendarAvailabilityRange.Day && 'child:bg-gradient-to-b child:from-red-100/70 child:from-50% child:to-red-100/70 child:to-50%',
      )}
      role="cell"
    >
      { content }
      { children }
    </div>
  )
}

export default DateCellBg
