import {
  type ChargeCategory,
  type ChargeDescription,
  type Charge,
  type ChargeWithoutSubtotal,
} from '../../../../modules/invoices'
import { type Currency } from '../../../../common/amount'
import { getRowType, showTaxable } from './ChargeRow/ChargeRow.utils'
import { type ChargesFormValue } from './ChargesFormContent.type'

const formatPriceAsString = (price: number) => {
  return String((Math.round(price * 100) / 100).toFixed(2))
}

export const chargesToFormData = (
  charges: Charge[],
): ChargesFormValue => {
  return {
    charges: charges.map(charge => ({
      categoryAndDescription: `${charge.pricingCategory}.${charge.description}`,
      quantity: String(charge.quantity),
      unit: charge.unit ?? 'units',
      unitPrice: formatPriceAsString(charge.unitPrice.price),
      item: String(charge.item),
      taxable: charge.taxable,
    })),
  }
}

export const formatChargesFormOutput = (data: ChargesFormValue, currency: Currency): ChargeWithoutSubtotal[] => {
  return data.charges.map((charge) => {
    const [
      pricingCategory,
      description,
    ] = charge.categoryAndDescription.split('.') as [ChargeCategory, ChargeDescription]
    const rowType = getRowType(description)
    const shouldShowTaxable = showTaxable(rowType)

    return {
      pricingCategory,
      description,
      item: charge.item,
      quantity: parseFloat(charge.quantity),
      unit: charge.unit === 'units' ? undefined : charge.unit,
      unitPrice: {
        price: parseFloat(charge.unitPrice),
        currency,
      },
      taxable: shouldShowTaxable ? charge.taxable : undefined,
    }
  })
}
