import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Interval } from 'date-fns'
import { startOfMonth } from 'date-fns/startOfMonth'
import { endOfMonth } from 'date-fns/endOfMonth'

import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import { useCurrentCompanyBranch } from '../../../modules/companies'
import Spinner from '../../../components/Spinner/Spinner'
import { Error404Page } from '../../errors'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import Calendar from './Calendar'
import PageTitle from '../../../components/layout/PageTitle/PageTitle'
import PageContent from '../../../components/layout/PageContent/PageContent'
import RuleModal, { type RuleType } from './RuleModal'
import RuleList from './RuleList'
import Toolbar from './Toolbar'
import { protectPage } from '../../../modules/users'

const CompanyBranchServicesPage: React.FC = () => {
  const { t } = useTranslation()
  const { replace } = useNavigate()

  const now = new Date()
  const [showPricingRules, setShowPricingRules] = useState(false)
  const [showAvailabilities, setShowAvailabilities] = useState(true)
  const [currentPeriod, setCurrentPeriod] = useState<Interval>({ start: startOfMonth(now), end: endOfMonth(now) })
  const [ruleModalOpen, setRuleModalOpen] = useState(false)
  const [selectedDates, setSelectedDates] = useState<{ start: Date, end?: Date }>()
  const [selectedCreationType, setSelectedCreationType] = useState<RuleType | null>(null)
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>()
  const {
    data: currentCompanyBranch,
    error: currentCompanyBranchError,
    loading: currentCompanyBranchLoading,
  } = useCurrentCompanyBranch()

  const companyReady = (currentCompanyBranch?.setupProgress ?? 0) === 100

  const onSelectDates = (start: Date, end?: Date) => {
    setSelectedRuleId(null)
    setSelectedCreationType(null)
    setSelectedDates({ start, end })
    setTimeout(() => {
      // fixme: find a cleaner solution
      setRuleModalOpen(true)
    }, 50)
  }

  const onCreateNewRule = (type: RuleType) => {
    setSelectedRuleId(null)
    setSelectedCreationType(type)
    setSelectedDates(undefined)
    setTimeout(() => {
      // fixme: find a cleaner solution
      setRuleModalOpen(true)
    }, 50)
  }

  const onSelectRule = (id: string) => {
    setSelectedRuleId(id)
    setRuleModalOpen(true)
  }

  useEffect(() => {
    if (!!currentCompanyBranchError || currentCompanyBranchLoading) {
      return
    }
    if (!companyReady) {
      replace(Route.BranchSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyReady, currentCompanyBranchError, currentCompanyBranchLoading])

  if (currentCompanyBranchLoading) {
    return <ContainedPage><Spinner floating /></ContainedPage>
  }

  if (currentCompanyBranchError) {
    return <Error404Page />
  }

  if (!companyReady || !currentCompanyBranch) {
    return null
  }

  const toolBar = (
    <Toolbar
      showPricingRules={showPricingRules}
      showAvailabilities={showAvailabilities}
      setShowPricingRules={setShowPricingRules}
      setShowAvailabilities={setShowAvailabilities}
      onCreateRuleClick={onCreateNewRule}
    />
  )

  return (
    <ContainedPage>
      <RuleModal
        open={ruleModalOpen}
        onClose={() => { setRuleModalOpen(false) }}
        selectedRuleId={selectedRuleId}
        selectedDates={selectedDates}
        creationType={selectedCreationType}
        onEditRule={onSelectRule}
      />

      <PageTitle fullWidth>
        { t('pages.companyBranch.availability.title') }
      </PageTitle>
      <PageContent fullWidth className="py-8">

        <div className="hidden lg:block">
          { toolBar }
        </div>

        <div className="flex flex-col gap-6 lg:flex-row">
          <div className="flex flex-1">
            <Calendar
              companyBranch={currentCompanyBranch}
              availabilityRules={currentCompanyBranch?.availabilityRules ?? []}
              pricingRules={currentCompanyBranch?.pricingRules ?? []}
              showAvailabilities={showAvailabilities}
              showRules={showPricingRules}
              currentPeriod={currentPeriod}
              onSelectDates={onSelectDates}
              onSelectRule={onSelectRule}
              onPeriodChange={setCurrentPeriod}
            />
          </div>
          <div className="lg:hidden">
            { toolBar }
          </div>
          <RuleList
            period={currentPeriod}
            availabilityRules={currentCompanyBranch?.availabilityRules ?? []}
            pricingRules={currentCompanyBranch?.pricingRules ?? []}
            onEditRule={onSelectRule}
          />
        </div>

      </PageContent>
    </ContainedPage>
  )
}

export default protectPage(CompanyBranchServicesPage)
