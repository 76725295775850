import { useFormContext, useWatch } from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox'
import Alert from '@mui/material/Alert'

import { mergeClassName } from '../../../../utils/mergeClassName'
import Paper from '../../../Paper/Paper'
import { type CompanyBranch, type CompanyBranchPricing } from '../../../../modules/companies'
import PricesInput from '../../../companyBranch/PricesInput'

type ItemValue = Record<string, CompanyBranchPricing<string>>

export type CardCheckboxPriceGroupProps = {
  name: string
  options: Record<string, React.ReactNode>
  companyBranch?: CompanyBranch
  allowDecimal?: boolean
  zeroDollarMessage?: React.ReactNode
  className?: string
  maxLength?: number
}

const CardCheckboxPriceGroup: React.FC<CardCheckboxPriceGroupProps> = ({
  name,
  options,
  companyBranch,
  allowDecimal,
  zeroDollarMessage,
  className,
  maxLength,
}) => {
  const { setValue, unregister } = useFormContext()
  const values: ItemValue = useWatch({ name }) ?? {}

  const onClick = (selectedKey: string) => {
    const selected = !!values[selectedKey]
    if (selected) {
      const updatedValues = Object.fromEntries(Object.entries(values).filter(([key]) => key !== selectedKey))
      setValue(name, updatedValues)
      unregister(`${name}.${selectedKey}`)
      return
    }
    setValue(name, { ...values, [selectedKey]: {} })
  }

  return (
    <div className={mergeClassName(
      'flex flex-col',
      className,
    )}
    >
      { Object.entries(options).map(([itemKey, label]) => {
        const checked = !!values[itemKey]
        return (
          <Paper
            key={itemKey}
            className="my-2 child:lg:p-5"
          >
            <label
              className={mergeClassName(
                'inline-flex items-center cursor-pointer',
                checked && 'text-black child:text-black',
              )}
            >
              <Checkbox
                checked={checked}
                onClick={() => { onClick(itemKey) }}
              />
              <div className={mergeClassName(
                'flex items-center',
                !checked && 'opacity-60',
              )}
              >
                { label }
              </div>
            </label>
            { checked && (
              <div className="mt-3 lg:pl-[75px]">
                <PricesInput
                  prefixName={`${name}.${itemKey}`}
                  companyBranch={companyBranch}
                  basePriceRequired
                  allowDecimal={allowDecimal}
                  maxLength={maxLength}
                />

                {
                  zeroDollarMessage &&
                  values[itemKey].basePrice &&
                  parseFloat(values[itemKey].basePrice ?? '0') === 0 && (
                    <div className="mt-3 flex self-start">
                      <Alert severity="info">
                        <div className="text-sm">
                          { zeroDollarMessage }
                        </div>
                      </Alert>
                    </div>
                  )
}
              </div>
            ) }
          </Paper>
        )
      }) }
    </div>
  )
}

export default CardCheckboxPriceGroup
