import {
  createStore,
  combineReducers,
  applyMiddleware,
  type Store,
  type AnyAction,
} from 'redux'
import {
  useSelector as useReactReduxSelector,
  useDispatch as useReactReduxDispatch,
} from 'react-redux'
import { persistStore } from 'redux-persist'
import {
  thunk,
  type ThunkAction as ReduxThunkAction,
  type ThunkDispatch as ReduxThunkDispatch,
} from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import config from '../config'
import { key as usersKey, reducer as usersReducer } from '../modules/users'
import { key as themeKey, reducer as themeReducer } from '../modules/theme'
import { key as graphQlKey, reducer as graphQlReducer } from '../modules/graphQl'

const { isProduction } = config

const mainReducer = combineReducers({
  [graphQlKey]: graphQlReducer,
  [themeKey]: themeReducer,
  [usersKey]: usersReducer,
})

const middleWares = [
  thunk,
]

let middleware = applyMiddleware(...middleWares)

if (!isProduction) {
  middleware = composeWithDevTools(middleware)
}

export const getStore = (defaultState?: any) => {
  return createStore(mainReducer, defaultState, middleware)
}

const store = getStore()

/* infer store state type */

export type StoreState = ReturnType<typeof store.getState>

/* define thunk types */

export type ThunkAction<ReturnType> = ReduxThunkAction<ReturnType, StoreState, unknown, AnyAction>
export type ThunkDispatch = ReduxThunkDispatch<StoreState, unknown, AnyAction>

export const useDispatch = () => useReactReduxDispatch<ThunkDispatch>()
export const useSelector = useReactReduxSelector

export const persistor = persistStore(store as unknown as Store<any, AnyAction>)
export default store
