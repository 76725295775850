import { Calendar as BigCalendar, Views } from 'react-big-calendar'
import { type Interval } from 'date-fns'
import { startOfMonth } from 'date-fns/startOfMonth'
import { endOfMonth } from 'date-fns/endOfMonth'

import './style.css'
import { type CalendarRule, type CalendarAvailability, type CalendarContent } from './RuleCalendar.type'
import useCalendarLanguageSettings from './useCalendarLanguageSettings'
import useCalendarRuleEvents from './useCalendarRuleEvents'
import MonthHeader from './MonthHeader'
import DateCell from './DateCell'
import DateCellBg from './DateCellBg'
import EventWrapper from './EventWrapper'
import EventBox from './EventBox'
import { CalendarContextProvider } from './CalendarContext'

type RuleCalendarProps = {
  rules?: CalendarRule[]
  availabilities?: CalendarAvailability[]
  content?: CalendarContent[]
  showRules?: boolean
  showAvailabilities?: boolean
  onSelectDates?: (start: Date, end?: Date) => void
  onSelectRule?: (id: string) => void
  onPeriodChange?: (period: Interval) => void
}

const RuleCalendar: React.FC<RuleCalendarProps> = ({
  rules,
  availabilities,
  content,
  showRules = false,
  showAvailabilities = true,
  onSelectDates,
  onSelectRule,
  onPeriodChange,
}) => {
  const languageSettings = useCalendarLanguageSettings()
  const events = useCalendarRuleEvents(showRules ? rules : undefined)

  return (
    <CalendarContextProvider
      availabilities={showAvailabilities ? availabilities : undefined}
      content={showRules ? undefined : content}
    >
      <BigCalendar
        {...languageSettings}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={[Views.MONTH]}
        style={{ height: 'calc(100dvh - 340px)', minHeight: 500, maxHeight: 775, width: '100%' }}
        components={{
          eventWrapper: EventWrapper,
          event: EventBox,
          dateCellWrapper: DateCellBg,
          month: {
            header: MonthHeader,
            dateHeader: DateCell,
          },
        }}
        showAllEvents
        selectable
        longPressThreshold={75}
        onSelectSlot={onSelectDates
          ? ({ slots }) => {
              if (slots.length === 1) {
                onSelectDates(slots[0])
                return
              }
              onSelectDates(slots[0], slots[slots.length - 1])
            }
          : undefined}
        onSelectEvent={onSelectRule
          ? ({ resource }) => {
              if (resource.id) {
                onSelectRule(resource.id)
              }
            }
          : undefined}
        onNavigate={onPeriodChange
          ? (date) => {
              onPeriodChange({
                start: startOfMonth(date),
                end: endOfMonth(date),
              })
            }
          : undefined}
      />
    </CalendarContextProvider>
  )
}

export default RuleCalendar
