import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CloseIcon from '@mui/icons-material/Close'

import { mergeClassName } from '../../utils/mergeClassName'

type CompanyBranchPanelProps = {
  open: boolean
  onClose: () => void
  className?: string
  header?: React.ReactNode
  children?: React.ReactNode
}

const CompanyBranchPanel: React.FC<CompanyBranchPanelProps> = ({
  open,
  onClose,
  className,
  header,
  children,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed top-[60px] z-50"
        onClose={onClose}
      >
        { /* backdrop */ }
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-[60px] bg-gray-600/50 backdrop-blur-sm" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog.Panel
            className="fixed right-0 mr-[15px] mt-[15px] inline-flex h-[calc(100dvh-90px)] w-[calc(100dvw-30px)] overflow-y-auto rounded-md border-t bg-white lg:m-0 lg:h-[calc(100dvh-60px)] lg:w-[800px] lg:max-w-[90vw] lg:rounded-r-none"
          >
            <div className="fixed right-2 top-2">
              <CloseIcon
                className="cursor-pointer !text-[32px]"
                onClick={onClose}
              />
            </div>
            <div className="flex size-full flex-col px-0 py-3 lg:p-3">
              { header }

              { /* hack to prevent headless auto-focus */ }
              { /* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */ }
              <a href="#" className="absolute opacity-0" onClick={event => { event.preventDefault() }} />
              <div
                className={mergeClassName(
                  'h-full grow overflow-auto',
                  className,
                )}
              >
                { children }
              </div>
            </div>

          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export default CompanyBranchPanel
