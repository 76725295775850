import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import MoneyIcon from '@mui/icons-material/MonetizationOnRounded'
import CalendarBusyIcon from '@mui/icons-material/EventBusy'

import { RuleType } from './RuleModal'
import { useBreakpoint } from '../../../modules/theme'

type ToolbarProps = {
  showPricingRules: boolean
  showAvailabilities: boolean
  setShowPricingRules: (state: boolean) => void
  setShowAvailabilities: (state: boolean) => void
  onCreateRuleClick: (type: RuleType) => void
}

const Toolbar: React.FC<ToolbarProps> = ({
  showPricingRules,
  showAvailabilities,
  setShowPricingRules,
  setShowAvailabilities,
  onCreateRuleClick,
}) => {
  const { t } = useTranslation()
  const isLargeScreen = useBreakpoint('lg')

  return (
    <div className="flex flex-col-reverse justify-between lg:mb-3 lg:flex-row">
      <div className="hidden flex-col items-center lg:flex lg:flex-row lg:gap-3">
        <label className="cursor-pointer">
          <Switch
            checked={showPricingRules}
            onChange={(_, checked) => { setShowPricingRules(checked) }}
          />
          { t('pages.companyBranch.availability.header.showPricingRules') }
        </label>

        <label className="cursor-pointer">
          <Switch
            checked={showAvailabilities}
            onChange={(_, checked) => { setShowAvailabilities(checked) }}
          />
          { t('pages.companyBranch.availability.header.showAvailabilities') }
        </label>
      </div>
      <div className="flex justify-center gap-3 pb-3 lg:justify-start lg:pb-0">
        <Button
          variant="contained"
          size={isLargeScreen ? 'small' : 'medium'}
          startIcon={<MoneyIcon />}
          color="secondary"
          onClick={() => { onCreateRuleClick(RuleType.Pricing) }}
          className="!leading-tight"
        >
          { t('pages.companyBranch.availability.header.setPricing') }
        </Button>

        <Button
          variant="contained"
          size={isLargeScreen ? 'small' : 'medium'}
          startIcon={<CalendarBusyIcon />}
          color="secondary"
          onClick={() => { onCreateRuleClick(RuleType.Availability) }}
          className="!leading-tight"
        >
          { t('pages.companyBranch.availability.header.setAvailability') }
        </Button>
      </div>
    </div>
  )
}

export default Toolbar
