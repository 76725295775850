export const getCompanyBranchRouteRegExp = (route: string) => {
  const anyCompanyRegExp = '([^/]+/)?'
  const anyBranchRegExp = '[^/]*'

  return new RegExp(
    route
      .replace(':companyId?/', anyCompanyRegExp)
      .replace(':branchId', anyBranchRegExp),
  )
}
