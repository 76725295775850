export enum FileSizeUnit {
  'B' = 'B',
  'KB' = 'KB',
  'MB' = 'MB',
  'GB' = 'GB',
  'TB' = 'TB',
}

const IEC_UNIT_POWER = 1024

export const formatFileSize = (bytes: number) => {
  if (bytes < IEC_UNIT_POWER) {
    return `${bytes} ${FileSizeUnit.B}`
  }

  let u = -1
  const units = Object.values(FileSizeUnit).filter(unit => unit !== FileSizeUnit.B)

  do {
    bytes /= IEC_UNIT_POWER
    ++u
  } while (Math.abs(bytes) >= IEC_UNIT_POWER && u < units.length - 1)

  return `${Math.ceil(bytes)} ${units[u]}`
}

export const downloadBase64Data = (data: string, filename: string) => {
  const blob = base64toBlob(data)
  const blobUrl = URL.createObjectURL(blob)

  const anchorElement = document.createElement('a')
  const fileName = 'companies.csv'
  anchorElement.href = blobUrl
  anchorElement.download = fileName
  anchorElement.click()
}

const base64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
