import * as Yup from 'yup'

import { type LegalFormValues } from './LegalForm.type'
import { useAddressValidation } from '../../../form/fields/AddressField'
import { usePhoneNumberValidator } from '../../../form/fields/PhoneNumberField/validator'
import { Accreditation, usePriceValidation } from '../../../../modules/companies'

const useValidationSchema = () => {
  const addressValidation = useAddressValidation()
  const validatePhoneNumber = usePhoneNumberValidator()
  const priceValidator = usePriceValidation()
  const priceValidation = priceValidator({ allowDecimal: false, required: true, isBasePrice: true })

  return Yup.object<LegalFormValues>({
    legalName: Yup.string().required(),
    companyAddress: addressValidation,
    owner: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      phoneNumber: validatePhoneNumber({ required: true }),
    }),
    insurances: Yup.object({
      commercialLiabilityCoverageLimit: priceValidation,
      cargoCoverageLimit: priceValidation,
    }),
    bbbRating: Yup.string().when('accreditations', ([accreditations], schema) => {
      if (accreditations?.includes(Accreditation.Bbb)) {
        return schema.required()
      }
      return schema.notRequired()
    }),
  }) as Yup.ObjectSchema<LegalFormValues, LegalFormValues>
}

export default useValidationSchema
