import { useMemo } from 'react'
import {
  useNavigate as reactRouterUseNavigate,
  useLocation as reactRouterUseLocation,
  useParams as reactRouterUseParams,
  generatePath,
} from 'react-router-dom'

export const useHostname = () => {
  /* istanbul ignore next ; cannot test ssr */
  if (typeof window === 'undefined') {
    return undefined
  }
  return window.location.hostname
}

export const useLocation = () => {
  return reactRouterUseLocation()
}

export const useParams = () => {
  return reactRouterUseParams()
}

export const useQueryString = (key?: string) => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  if (key) {
    return getStringOrArray(searchParams.getAll(key))
  }
  const keys = Array.from(searchParams.keys())
  const entries = keys.map((key: string) => [key, getStringOrArray(searchParams.getAll(key))])
  return Object.fromEntries(entries)
}

export const usePathGenerator = () => {
  const params = useParams()
  return (path: string, extraParams?: Record<string, string>) => {
    if (path.startsWith('http:') || path.startsWith('https:')) {
      return path
    }
    return generatePath(path, { ...params, ...extraParams })
  }
}

export const useNavigate = () => {
  const navigate = reactRouterUseNavigate()
  const generatePath = usePathGenerator()
  return {
    navigate: (
      to: string,
      params?: Record<string, string>,
      newTab = false,
    ) => {
      const url = generatePath(to, params)
      if (newTab) {
        window.open(url, '_blank')?.focus()
        return
      }
      navigate(url)
    },
    push: (to: string, params?: Record<string, string>) => { navigate(generatePath(to, params)) },
    replace: (to: string, params?: Record<string, string>) => { navigate(generatePath(to, params), { replace: true }) },
  }
}

const getStringOrArray = (values: string | string[]) => values.length > 1 ? values : values[0]
