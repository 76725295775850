import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useAddressValidation } from '../../../form/fields/AddressField'
import { type TripAndTravelFormValues } from './TripAndTravel.type'
import { MAX_PICKUP_RADIUS, MIN_PICKUP_RADIUS } from './TripAndTravel.const'
import { Truck, usePricesValidation } from '../../../../modules/companies'

const useValidationSchema = () => {
  const { t } = useTranslation()

  const validAddress = useAddressValidation()
  const pricesValidation = usePricesValidation()

  const toValidation = Yup.number().test(
    'validTo',
    'Invalid range',
    (value, { createError, options, from }, ...y) => {
      const index = (options as any)?.index ?? 0
      const rules = (from?.[1] as any)?.value?.rules ?? []
      const maxDistance = parseInt((from?.[1] as any)?.value?.maxDistance ?? '0')
      const rulesTo: number[] = rules.map((r: any) => parseInt(r.to))
      const isFirst = index === 0
      const isLast = index === rulesTo.length - 1

      const maxAllowed = maxDistance - 2

      if (isLast || !maxDistance || value === undefined) {
        return true
      }

      if (value > maxAllowed) {
        return createError({
          message: t('forms.companyBranch.tripAndTravel.validationErrors.max', { max: maxAllowed }),
        })
      }

      if (isFirst) {
        if (value < 1) {
          return createError({
            message: t('forms.companyBranch.tripAndTravel.validationErrors.min', { min: 1 }),
          })
        }
        return true
      }

      const previousValue = rulesTo[index - 1]
      if (!previousValue) {
        return true
      }
      const minAllowed = previousValue + 2

      if (value < minAllowed) {
        if (minAllowed > maxAllowed) {
          return createError({
            message: t('forms.companyBranch.tripAndTravel.validationErrors.invalidPreviousRanges'),
          })
        }
        return createError({
          message: t('forms.companyBranch.tripAndTravel.validationErrors.min', { min: minAllowed }),
        })
      }
      return true
    },
  )

  const ruleValidation = Yup.object({
    to: toValidation,

    travel: Yup.object({
      minLabourTime: Yup.string().matches(/^([0-9](\.[0-9])?)?$/, t('forms.companyBranch.tripAndTravel.validationErrors.invalidHours')),
      pricePerKm: pricesValidation({ allowZero: true }),
    }),
    transport: Yup.object({
      pricePerKm: pricesValidation({ allowZero: true }),
    }),
    trucksFee: Yup.object(Object.fromEntries(Object.values(Truck).map(truck => ([
      truck,
      pricesValidation({ allowDecimal: false, allowZero: true }),
    ])))),
  })

  return Yup.object<TripAndTravelFormValues>({
    trucksOriginAddress: validAddress,
    maxPickupRadius: Yup.number().required().min(MIN_PICKUP_RADIUS).max(MAX_PICKUP_RADIUS),
    maxDistance: Yup.number().required().min(
      Yup.ref<number>('maxPickupRadius'),
      t('forms.companyBranch.tripAndTravel.validationErrors.invalidMaxDistance'),
    ),

    rules: Yup.array().of(ruleValidation),
  }) as Yup.ObjectSchema<TripAndTravelFormValues, TripAndTravelFormValues>
}

export default useValidationSchema
