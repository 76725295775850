import { createContext, useContext } from 'react'

import { type CalendarContextType } from './RuleCalendar.type'

const CalendarContext = createContext<CalendarContextType>({})

type CalendarContextProviderProps = CalendarContextType & {
  children?: React.ReactNode
}

export const CalendarContextProvider: React.FC<CalendarContextProviderProps> = ({
  availabilities,
  content,
  children,
}) => {
  return (
    <CalendarContext.Provider value={{ availabilities, content }}>
      { children }
    </CalendarContext.Provider>
  )
}

export const useCalendar = () => useContext(CalendarContext)
