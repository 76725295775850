import { useTranslation } from 'react-i18next'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import { type LightJobRequest } from '../../../modules/jobRequests'
import { calculateNumberOfTrucks } from '../../../modules/quotes'
import { formatNumber } from '../../../utils/number'

type MoveSizeProps = {
  jobRequest: LightJobRequest
}

const MoveSize: React.FC<MoveSizeProps> = ({ jobRequest }) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-center gap-2 text-center lg:min-w-[240px] lg:justify-start lg:text-left">
      <TruckIcon className="mt-[5px] !text-[18px] text-neutral-400" />
      <div>
        <div className="text-lg text-neutral-500">
          { t('nbMen', { count: jobRequest.nbMen }) }
          { ' + ' }
          { t('nbTrucks', { count: calculateNumberOfTrucks(jobRequest.nbTrucks) }) }
        </div>

        <div>
          <span className="text-sm text-neutral-400">
            { t(`residenceType.${jobRequest.residenceType}.title`, { defaultValue: jobRequest.residenceType }) }

            <span className="ml-3 inline-block font-bold text-neutral-500">
              { formatNumber(jobRequest.weightPounds) }
              { ' ' }
              { t('units.pounds') }
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default MoveSize
