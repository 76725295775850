import ServicesIcon from '@mui/icons-material/HandymanOutlined'
import SeasonsIcon from '@mui/icons-material/CalendarMonthOutlined'
import LabourIcon from '@mui/icons-material/GroupOutlined'
import TripIcon from '@mui/icons-material/MapOutlined'
import ChargesIcon from '@mui/icons-material/ShoppingCartOutlined'
import ProtectionIcon from '@mui/icons-material/HealthAndSafetyOutlined'
import CommunicationIcon from '@mui/icons-material/PermPhoneMsgOutlined'
import LegalIcon from '@mui/icons-material/Policy'

import Route from '../../app/Route.enum'
import Step from './Step.enum'
import { type Icon } from '../../common/icon/Icon.type'
import { getLastPathnameElement } from '../../utils/routing/routing'

export type StepInfo = {
  route: string
  routeSlug: string
  branchProperty: string
  Icon: Icon
}

const steps: Record<Step, StepInfo> = {
  [Step.Legal]: {
    route: Route.BranchSettingsLegal,
    routeSlug: getLastPathnameElement(Route.BranchSettingsLegal),
    branchProperty: 'legal',
    Icon: LegalIcon,
  },
  [Step.Services]: {
    route: Route.BranchSettingsServices,
    routeSlug: getLastPathnameElement(Route.BranchSettingsServices),
    branchProperty: 'services',
    Icon: ServicesIcon,
  },
  [Step.Seasons]: {
    route: Route.BranchSettingsSeasons,
    routeSlug: getLastPathnameElement(Route.BranchSettingsSeasons),
    branchProperty: 'seasons',
    Icon: SeasonsIcon,
  },
  [Step.Labour]: {
    route: Route.BranchSettingsLabour,
    routeSlug: getLastPathnameElement(Route.BranchSettingsLabour),
    branchProperty: 'labour',
    Icon: LabourIcon,
  },
  [Step.TripAndTravel]: {
    route: Route.BranchSettingsTripAndTravel,
    routeSlug: getLastPathnameElement(Route.BranchSettingsTripAndTravel),
    branchProperty: 'tripAndTravel',
    Icon: TripIcon,
  },
  [Step.AdditionalCharges]: {
    route: Route.BranchSettingsAdditionalCharges,
    routeSlug: getLastPathnameElement(Route.BranchSettingsAdditionalCharges),
    branchProperty: 'additionalCharges',
    Icon: ChargesIcon,
  },
  [Step.ProtectiveMaterial]: {
    route: Route.BranchSettingsProtecticeMaterial,
    routeSlug: getLastPathnameElement(Route.BranchSettingsProtecticeMaterial),
    branchProperty: 'protectiveMaterial',
    Icon: ProtectionIcon,
  },
  [Step.Communication]: {
    route: Route.BranchSettingsCommunication,
    routeSlug: getLastPathnameElement(Route.BranchSettingsCommunication),
    branchProperty: 'communication',
    Icon: CommunicationIcon,
  },
}

export default steps
