import { useTranslation } from 'react-i18next'

import { type Charge } from '../../../../modules/invoices'
import Price from '../../../Price'
import { useQuoteItemValueFormatter, useShowDescription } from '../../../../modules/quotes'
import { capitalizeFirstLetter } from '../../../../utils/string'

type QuoteRowProps = {
  charge: Charge
}

const QuoteRow: React.FC<QuoteRowProps> = ({ charge }) => {
  const { t } = useTranslation()
  const getItemValue = useQuoteItemValueFormatter()

  const item = getItemValue(charge.description, charge.item)
  const showDescription = useShowDescription(charge.description)
  const description = t(`quotes.pricing.description.${charge.description}`)

  return (
    <tr className="border-b border-dashed align-bottom sm:align-middle">
      <td className="w-1/2 sm:w-auto">
        <div className="mb-1 text-sm  text-gray-500 sm:mb-0">
          { showDescription
            ? (
              <>
                { description }
                { item && <> ({ capitalizeFirstLetter(item) })</> }
              </>
              )
            : capitalizeFirstLetter(item) }
        </div>
      </td>
      <td>
        <div className="hidden whitespace-nowrap text-right text-sm text-gray-400 sm:block">
          { charge.quantity }
          { charge.unit && t(`units.${charge.unit}`) }
        </div>
      </td>
      <td>
        <div className="hidden whitespace-nowrap text-right text-sm text-gray-400 sm:block">
          <Price amount={charge.unitPrice} compact />
        </div>
      </td>
      <td className="w-1/2 sm:w-auto">
        <div className="text-right text-gray-500">
          <div className="my-1 inline-flex flex-wrap justify-end gap-1 rounded bg-gray-200 px-2 py-px text-xs sm:hidden">
            <div>
              { charge.quantity }
              { charge.unit && t(`units.${charge.unit}`) } @
            </div>
            <div className="whitespace-nowrap">
              <Price amount={charge.unitPrice} compact />
            </div>
          </div>
          <div className="mb-1 whitespace-nowrap font-semibold sm:mb-0 sm:font-normal">
            <Price amount={charge.subtotal} compact />
          </div>
        </div>
      </td>
    </tr>
  )
}

export default QuoteRow
