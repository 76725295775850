import { useTranslation } from 'react-i18next'
import MapIcon from '@mui/icons-material/MapOutlined'
import DurationIcon from '@mui/icons-material/UpdateOutlined'

import { type Quote, useQuoteConstraints } from '../../../../modules/quotes'
import { type PromoCode } from '../../../../modules/adminPromoCodes'
import { useTimeFormatter } from '../../../../utils/time'
import QuoteDetails from '../QuoteDetails'
import AlternativeDates from '../AlternativeDates'
import QuoteConstraints from '../QuoteConstraints'

type QuoteContentProps = {
  quote: Quote
  promoCode?: PromoCode
  presentationMode?: boolean
}

const QuoteContent: React.FC<QuoteContentProps> = ({
  quote,
  promoCode,
  presentationMode = false,
}) => {
  const { t } = useTranslation()
  const constraints = useQuoteConstraints(quote)
  const { formatSeconds } = useTimeFormatter()

  const stopEventPropagation = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (

    <div
      className="cursor-auto border-t border-dashed border-gray-300 px-3 pb-6 pt-2 2xl:px-10"
      onClick={stopEventPropagation}
    >

      <div className="pt-3 2xl:hidden">
        { (constraints?.length ?? 0) > 0 && (
          <QuoteConstraints quote={quote} />
        ) }
      </div>

      <div className="flex flex-col-reverse justify-between gap-2 2xl:flex-row 2xl:gap-6">
        <div className="flex flex-col justify-between md:pt-4 ">
          <div>

            <div className="hidden 2xl:block">
              { (quote.constraints?.length ?? 0) > 0 && (
                <QuoteConstraints quote={quote} />
              ) }
            </div>

            <div className="mb-12 flex justify-around gap-4 sm:gap-6 md:gap-8 2xl:justify-normal">
              <div>
                <div className="mb-2 pt-4 text-sm font-bold leading-tight text-gray-600 sm:mb-0">
                  <MapIcon />
                  { ' ' }
                  { t('quotes.quote.totalDistance') }
                </div>
                <div className="text-gray-500 sm:pl-6">
                  { (quote.totalDistance / 1000).toFixed(1) }
                  { ' ' }
                  { t('units.km') }
                </div>
              </div>

              <div>
                <div className="mb-2 pt-4 text-sm font-bold leading-tight text-gray-600 sm:mb-0">
                  <DurationIcon />
                  { ' ' }
                  { t('quotes.quote.totalDuration') }
                </div>
                <div className="text-gray-500 sm:pl-6">
                  { formatSeconds(quote.totalDuration) }
                </div>
              </div>
            </div>
          </div>

          { quote.alternativeDates?.length && !quote.customized && (
            <AlternativeDates
              quote={quote}
              promoCode={promoCode}
              className="mb-5"
            />
          ) }

        </div>

        <div className="2xl:w-1/2 2xl:min-w-[500px]">
          <QuoteDetails
            quote={quote}
            promoCode={promoCode}
            forceOpen={presentationMode}
          />
        </div>
      </div>

    </div>
  )
}

export default QuoteContent
