import { type JobRequestForPartner } from '../../../modules/jobRequests'
import JobsTabs from '../../../components/move/JobsTabs'
import Job from './Job'

type JobsProps = {
  jobRequest: JobRequestForPartner
}

const Jobs: React.FC<JobsProps> = ({
  jobRequest,
}) => {
  return (
    <>
      <JobsTabs move={jobRequest.move} />
      <Job jobRequest={jobRequest} />
    </>
  )
}

export default Jobs
