import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import InvoiceIcon from '@mui/icons-material/RequestQuoteOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import {
  type InvoicePayload,
  InvoiceCategory,
  useCreateMoveInvoiveAction,
} from '../../../../../modules/invoices'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import {
  Modal,
  ModalDescription,
  ModalHeader,
  ModalTitle,
} from '../../../../../components/Modal'
import InvoiceForm from '../../../../../components/forms/invoice/InvoiceForm'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import {
  errorNotification,
  successNotification,
  warningNotification,
} from '../../../../../components/ToastNotifications'

const THIRTY_SECONDS_MS = 30 * 1000

type CreateInvoiceModalProps = {
  move: FullMove
  open: boolean
  onClose: () => void
}

const CreateInvoiceModal: React.FC<CreateInvoiceModalProps> = ({
  move,
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const { isAcceptedByCustomer, hasMoveInvoice } = useMoveState().forMove(move)
  const canCreateMoveInvoice = isAcceptedByCustomer && !hasMoveInvoice

  const [category, setCategory] = useState<InvoiceCategory>()
  const { createMoveInvoice } = useCreateMoveInvoiveAction()
  const formatError = useErrorFormatter()

  const handleSubmit = async (payload: InvoicePayload) => {
    try {
      const {
        paymentCaptured,
        paymentError,
      } = await createMoveInvoice(move.id, { ...payload, companyBranchId: move.selectedBranch })

      if (paymentError) {
        warningNotification(
          <>
            <strong>
              { t('pages.admin.move.accounting.createInvoiceModal.invoiceSuccess') }
            </strong>
            <div className="max-w-[350px]">
              { t('pages.admin.move.accounting.createInvoiceModal.paymentError') }
            </div>
          </>,
          { autoClose: THIRTY_SECONDS_MS })
      } else {
        if (paymentCaptured) {
          successNotification(t('pages.admin.move.accounting.createInvoiceModal.invoiceAndPaymentSuccess'))
        } else {
          successNotification(t('pages.admin.move.accounting.createInvoiceModal.invoiceSuccess'))
        }
      }

      onClose()
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  /**
   * reset invoice category when modal is open
   */
  useEffect(() => {
    setCategory(canCreateMoveInvoice ? undefined : InvoiceCategory.adjustment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal
      open={open}
      className={mergeClassName(
        'lg:max-w-screen-md',
        category && 'lg:max-w-screen-3xl',
      )}
      showCloseIcon={!category}
      onClose={category ? undefined : onClose}
    >
      <ModalHeader>
        <ModalTitle>
          { t('pages.admin.move.accounting.createInvoiceModal.title') }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription>

        { category
          ? (
            <InvoiceForm
              move={move}
              category={category}
              onCancel={onClose}
              onSubmit={handleSubmit}
            />
            )
          : (
            <div>
              { t('pages.admin.move.accounting.createInvoiceModal.subtitle') }

              <div className="my-8 flex flex-wrap gap-4">

                <div
                  className="flex flex-1 cursor-pointer gap-x-4 rounded-2xl bg-slate-500 px-8 py-4 text-white hover:bg-slate-500/90"
                  onClick={() => { setCategory(InvoiceCategory.move) }}
                >
                  <div className="flex items-center">
                    <TruckIcon className="!text-[36px] text-slate-100" />
                  </div>
                  <div>
                    <div className="text-xl">
                      { t('pages.admin.move.accounting.createInvoiceModal.moveInvoice.title') }
                    </div>
                    <div className="mt-1 text-slate-300">
                      { t('pages.admin.move.accounting.createInvoiceModal.moveInvoice.subtitle') }
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-1 cursor-pointer gap-x-4 rounded-2xl bg-slate-500 px-8 py-4 text-white hover:bg-slate-500/90"
                  onClick={() => { setCategory(InvoiceCategory.adjustment) }}
                >
                  <div className="flex items-center">
                    <InvoiceIcon className="!text-[36px] text-slate-100" />
                  </div>
                  <div>
                    <div className="text-xl">
                      { t('pages.admin.move.accounting.createInvoiceModal.adjustmentInvoice.title') }
                    </div>
                    <div className="mt-1 text-slate-300">
                      { t('pages.admin.move.accounting.createInvoiceModal.adjustmentInvoice.subtitle') }
                    </div>
                  </div>
                </div>

              </div>
            </div>
            ) }

      </ModalDescription>
    </Modal>
  )
}

export default CreateInvoiceModal
