import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import {
  type Charge,
  type ChargeCategory,
} from '../../../../modules/invoices'
import { type Quote } from '../../../../modules/quotes'
import {
  type PromoCode,
  usePromoCodeRebate,
  useQuoteSubtotalWithPromoCode,
} from '../../../../modules/adminPromoCodes'
import { mergeClassName } from '../../../../utils/mergeClassName'
import { type Step, steps } from '../../../companyBranch'
import Price from '../../../Price'
import QuoteRow from './QuoteRow'

type RowsByCategories = Partial<Record<ChargeCategory, Charge[]>>

type QuoteDetailsProps = {
  quote: Quote
  promoCode?: PromoCode
  forceOpen?: boolean
}

const QuoteDetails: React.FC<QuoteDetailsProps> = ({
  quote,
  promoCode,
  forceOpen = false,
}) => {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(forceOpen)
  const promoCodeRebate = usePromoCodeRebate(quote, promoCode)
  const quoteSubtotalWithPromoCode = useQuoteSubtotalWithPromoCode(quote, promoCode)

  const handleToggleDetailsClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setShowDetails(!showDetails)
  }

  const rowsByCategories = useMemo(() => {
    const rows: RowsByCategories = {}
    quote.details.forEach((charge) => {
      const category = charge.pricingCategory
      if (!rows[category]) {
        rows[category] = []
      }
      rows[category]?.push(charge)
    })
    return rows
  }, [quote.details])

  /**
   * return category subtotal for simplified view
   */
  const getCategorySubtotal = (rows: Charge[] | undefined) => {
    if (!rows) {
      return 0
    }
    const currency = rows?.[0]?.subtotal?.currency
    if (!currency) {
      return
    }
    const categorySubtotal = rows.reduce((acc, curr) => {
      return acc + (curr.subtotal.price ?? 0)
    }, 0)
    return { price: categorySubtotal, currency }
  }

  return (
    <>
      <table className="w-full">
        <tbody>

          { Object.entries(rowsByCategories).map(([category, rows], index) => {
            const CategoryIcon = steps[category as Step]?.Icon
            const categoryName = t(`quotes.pricing.categories.${category}`)
            const showHeaders = showDetails && index === 0
            const categoryColSpan = showDetails ? 4 : undefined
            const categorySubtotal = getCategorySubtotal(rows)

            return (
              <Fragment key={category}>
                <tr className={mergeClassName(
                  !showDetails && 'border-b border-dashed align-bottom sm:align-middle',
                )}
                >
                  <td colSpan={showHeaders ? undefined : categoryColSpan}>
                    <div className={mergeClassName(
                      'pt-3 text-sm font-bold text-gray-600',
                      showDetails && 'pt-4',
                    )}
                    >
                      { CategoryIcon && <CategoryIcon /> }
                      { ' ' }
                      { categoryName }
                    </div>
                  </td>

                  { !showDetails && (
                    <td className="whitespace-nowrap text-right text-gray-500">
                      { categorySubtotal && <Price amount={categorySubtotal} compact /> }
                    </td>
                  ) }

                  { showHeaders && (
                    <>
                      <td className="w-0 opacity-0 sm:w-auto sm:opacity-100">
                        <div className="pt-4 text-right text-xs text-gray-400 ">
                          { t('quotes.quote.columns.quantity') }
                        </div>
                      </td>
                      <td className="w-0 opacity-0 sm:w-auto sm:opacity-100">
                        <div className="pt-4 text-right text-xs text-gray-400">
                          { t('quotes.quote.columns.unitPrice') }
                        </div>
                      </td>
                      <td className="w-0 opacity-0 sm:w-auto sm:opacity-100">
                        <div className="pt-4 text-right text-xs text-gray-400">
                          { t('quotes.quote.columns.subtotal') }
                        </div>
                      </td>
                    </>
                  ) }
                </tr>
                { showDetails && rows.map(row => {
                  const key = `${row.description}:${String(row.item)}:${row.quantity}:${String(row.unit)}`
                  return <QuoteRow key={key} charge={row} />
                }) }
              </Fragment>
            )
          }) }

          <tr>
            <td colSpan={4}>
              <div className="pt-2 text-right text-lg">

                { promoCodeRebate && quoteSubtotalWithPromoCode && (
                  <>
                    <div className="text-sm text-neutral-400">
                      <Price amount={quote.subtotal} compact />
                    </div>
                    <div className="mb-4 text-sm text-green-800">
                      <Price amount={promoCodeRebate} compact />
                    </div>
                  </>
                ) }

                <Price amount={quoteSubtotalWithPromoCode ?? quote.subtotal} compact />
              </div>
            </td>
          </tr>

        </tbody>
      </table>

      { !forceOpen && (
        <div>
          { showDetails
            ? (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleToggleDetailsClick}
                startIcon={<ArrowUpIcon />}
              >
                { t('actions.hideDetails') }
              </Button>
              )
            : (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleToggleDetailsClick}
                startIcon={<ArrowDownIcon />}
              >
                { t('actions.showDetails') }
              </Button>
              ) }
        </div>
      ) }

    </>
  )
}

export default QuoteDetails
