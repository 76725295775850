import { useQuery, useMutation } from '@apollo/client'

import { type QueryResult } from '../../graphQl'
import { type Company, type CompanyBranch } from './companies.models'
import * as queries from './companies.queries'
import * as mutations from './companies.mutations'
import { type CompanyBranchPayload, type CompanyPayload } from './companies.types'
import { useTranslatedValue } from '../../../common/translation'
import { useParams } from '../../navigation'
import { buildCompany } from './companies.builder'
import { useAuthenticationState, useCurrentUserAccountType } from '../../users'
import { useConfig } from '../../config'
import { type File } from '../../../common/file/file.model'

/**
 * returns current partner company
 */
export const useCurrentCompany = ({ reload = false, skip = false, ...queryArgs } = {}): QueryResult<Company | undefined> => {
  const { isAuthenticated } = useAuthenticationState()
  const { isPartner, isAdmin } = useCurrentUserAccountType()
  const { onBehalfCompanyId } = useOnBehalfState()
  const invalidUser = !isPartner && !isAdmin

  skip = skip || !isAuthenticated || invalidUser || (isAdmin && !onBehalfCompanyId)

  /* istanbul ignore next ; ignore data reloading */
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, error, ...queryProps } = useQuery(
    queries.getCurrentCompany,
    {
      skip,
      fetchPolicy,
      variables: { onBehalfCompanyId },
      ...queryArgs,
    },
  )

  return {
    data: buildCompany(data?.getCurrentCompany),
    error,
    ...queryProps,
  }
}

/**
 * returns currently selected company branch
 * note : at the moment, there is only 1 branch per company so we return the first one
 */
export const useCurrentCompanyBranch = (queryArgs = {}): QueryResult<CompanyBranch | undefined> => {
  const { data: company, ...companyResult } = useCurrentCompany(queryArgs)
  const { branchId } = useParams()

  if (branchId && !companyResult.loading) {
    const currentBranch = company?.branches?.find(({ id }) => id === branchId)
    if (currentBranch) {
      return {
        loading: companyResult.loading,
        called: companyResult.called,
        error: companyResult.error,
        data: company?.branches?.find(({ id }) => id === branchId),
      }
    }
    return {
      loading: companyResult.loading,
      called: companyResult.called,
      data: undefined,
      error: new Error('Invalid company branch id'),
    }
  }

  return {
    data: company?.branches?.[0],
    loading: companyResult.loading,
    called: companyResult.called,
    error: companyResult.error,
  }
}

/**
 * return true if admin is currently acting on behalf of a partner
 */
export const useOnBehalfState = () => {
  const { companyId } = useParams()
  const { isAdmin } = useCurrentUserAccountType()
  const onBehalf = isAdmin && !!companyId

  return {
    onBehalf,
    onBehalfCompanyId: onBehalf ? companyId : undefined,
  }
}

/**
 * returns currently selected company branch name (if not defined, we use the parent company name)
 */
export const useCurrentCompanyBranchName = () => {
  const { data: currentBranch } = useCurrentCompanyBranch()
  const { data: currentCompany } = useCurrentCompany()
  const getTranslation = useTranslatedValue()

  return getTranslation(currentBranch?.identification?.name) ??
  getTranslation(currentCompany?.identification.name)
}

/**
 * create a company
 */
export const useCreateCompanyAction = () => {
  const [createCompany, { data, loading, ...mutationProps }] = useMutation(mutations.createCompany)

  return {
    createCompany: async (payload: CompanyPayload) => {
      const { data } = await createCompany({
        variables: {
          payload,
        },
      })

      return data.createCompany
    },
    data: data?.createCompany,
    loading,
    ...mutationProps,
  }
}

/**
 * update a company
 */
export const useUpdateCompanyAction = () => {
  const [updateCompany, { data, loading, ...mutationProps }] = useMutation(mutations.updateCompany)
  const { onBehalfCompanyId } = useOnBehalfState()

  return {
    updateCompany: async (payload: CompanyPayload) => {
      const { data } = await updateCompany({
        variables: {
          payload,
          onBehalfCompanyId,
        },
      })

      return data.updateCompany
    },
    data: data?.updateCompany,
    loading,
    ...mutationProps,
  }
}

/**
 * update a company branch
 */
export const useUpdateCompanyBranchAction = () => {
  const [updateCompanyBranch, { data, loading, ...mutationProps }] = useMutation(mutations.updateCompanyBranch)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    updateCompanyBranch: async (payload: CompanyBranchPayload): Promise<CompanyBranch> => {
      const { data } = await updateCompanyBranch({
        variables: {
          branchId: currentCompanyBranch?.id,
          payload,
        },
      })

      return data.updateCompanyBranch
    },
    data: data?.updateCompanyBranch,
    loading,
    ...mutationProps,
  }
}

/**
 * delete legal documents from a company branch
 */
export const useDeleteLegalDocumentsAction = () => {
  const [deleteLegalDocuments, { data, loading, ...mutationProps }] = useMutation(mutations.deleteLegalDocuments)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    deleteLegalDocuments: async (fileIds: string[]): Promise<CompanyBranch> => {
      const { data } = await deleteLegalDocuments({
        variables: {
          branchId: currentCompanyBranch?.id,
          fileIds,
        },
      })

      return data.deleteLegalDocuments
    },
    data: data?.deleteLegalDocuments,
    loading,
    ...mutationProps,
  }
}

export const useLegalFileUrlGenerator = (companyBranch?: CompanyBranch) => {
  const apiUrl = useConfig('api.url')
  const legalFilesServerPath = useConfig('companies.legalFilesServerPath')

  return (file: File) => {
    if (!companyBranch) {
      return
    }
    return [
      apiUrl,
      legalFilesServerPath,
      companyBranch.id,
      file.id,
      file.filename,
    ].join('/')
  }
}
