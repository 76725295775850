import { useTranslation } from 'react-i18next'
import DashboardIcon from '@mui/icons-material/SpaceDashboardOutlined'
import CompaniesIcon from '@mui/icons-material/BusinessOutlined'
import UsersIcon from '@mui/icons-material/AccountCircleOutlined'
import MoveIcon from '@mui/icons-material/PermContactCalendarOutlined'
import QuotesIcon from '@mui/icons-material/ScienceOutlined'
import ProspectsIcon from '@mui/icons-material/HeadsetMicOutlined'
import PromoCodesIcon from '@mui/icons-material/LocalOfferOutlined'

import MenuItem from './MenuItem'
import Route from '../../../app/Route.enum'
import { Permission, usePermission } from '../../../modules/users'

const AdminMenuContent: React.FC = () => {
  const { t } = useTranslation()
  const canReadUsers = usePermission(Permission.canReadUsers)
  const canReadMoves = usePermission(Permission.canReadMoves)
  const canReadCompanies = usePermission(Permission.canReadCompanies)
  const canManageProspects = usePermission(Permission.canManageProspects)
  const canGenerateDetailedQuotes = usePermission(Permission.canGenerateDetailedQuotes)
  const canReadPromoCodes = usePermission(Permission.canReadPromoCodes)

  return (
    <>
      <MenuItem
        to={Route.Dashboard}
        label={t('menu.admin.dashboard')}
        Icon={DashboardIcon}
        exact
        adminStyle
      />

      { canReadCompanies && (
        <MenuItem
          to={Route.Companies}
          label={t('menu.admin.companies')}
          Icon={CompaniesIcon}
          adminStyle
        />
      ) }

      { canReadUsers && (
        <MenuItem
          to={Route.AdminUsers}
          label={t('menu.admin.users')}
          Icon={UsersIcon}
          adminStyle
        />
      ) }

      { canReadMoves && (
        <MenuItem
          to={Route.AdminMoves}
          label={t('menu.admin.moves')}
          Icon={MoveIcon}
          adminStyle
        />
      ) }

      { canManageProspects && (
        <MenuItem
          to={Route.Prospects}
          label={t('menu.admin.prospects')}
          Icon={ProspectsIcon}
          adminStyle
        />
      ) }

      { canGenerateDetailedQuotes && (
        <MenuItem
          to={Route.GetQuotes}
          label={t('menu.admin.getQuotes')}
          Icon={QuotesIcon}
          adminStyle
        />
      ) }

      { canReadPromoCodes && (
        <MenuItem
          to={Route.PromoCodes}
          label={t('menu.admin.promoCodes')}
          Icon={PromoCodesIcon}
          adminStyle
        />
      ) }
    </>
  )
}

export default AdminMenuContent
